import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse'
import axios from '../../api'
import { useNavigate } from 'react-router-dom'

import { IconContext } from "react-icons"
import { AiOutlineCaretDown, AiOutlineCaretUp, AiFillCloseCircle } from "react-icons/ai"
import { BsFillFileEarmarkBarGraphFill } from "react-icons/bs"

function AddCarModal({ setShowAddCarModal }) {

    const [dealer, setDealer] = useState(null)
    const navigate = useNavigate()

    const years = Array.from(new Array(new Date().getFullYear() - 1941), (val, index) => index + 1941).reverse()
    const [manufacturerOptions, setManufacturerOptions] = useState([])

    const [anno, setAnno] = useState(null)
    const [showAnnoList, setShowAnnoList] = useState(false)
    const [manufacturer, setManufacturer] = useState(null)
    const [showManufacturerList, setShowManufacturerList] = useState(false)

    const baseStyle = {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    };

    const focusedStyle = {
        opacity: 1,
        borderColor: '#2196f3'
    };

    const acceptStyle = {
        opacity: 1,
        zIndex: 55,
        borderColor: '#00e676'
    };

    const rejectStyle = {
        opacity: 1,
        zIndex: 55,
        borderColor: '#ff1744'
    };
    const [files, setFiles] = useState([]);
    const onDrop = useCallback((acceptedFiles) => {
        // Update your state with new files
        setFiles(acceptedFiles);
    }, []);
    const clearFiles = () => {
        setFiles([]);
    };
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
        acceptedFiles
    } = useDropzone({ onDrop, maxFiles: 1, accept: { 'text/csv': ['.csv'] } });
    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);
    const [showFileInput, setShowFileInput] = useState(false)
    const [totalCarInCsv, setTotalCarInCsv] = useState(0)
    const [totalCarUploaded, setTotalCarUploaded] = useState(0)
    const [showUploadProgress, setShowUploadProgress] = useState(false)
    const [uploadErrors, setUploadErrors] = useState(null)
    const [uploadDone, setUploadDone] = useState(false)

    const mapTraction = (traction) => {
        // ["all wheels", "rear wheels", "front wheels"]
        switch (traction) {
            case "Integrale":
                return "all wheels"
            case "Posteriore":
                return "rear wheels"
            case "Anteriore":
                return "front wheels"
            default:
                return "front wheels"
        }
    }

    const mapFuelType = (fuelType) => {
        // ["hybrid", "hybrid", "electric", "methane", "lpg", "diesel", "gasoline"]
        switch (fuelType) {
            case "Benzina":
                return "gasoline"
            case "Diesel":
                return "diesel"
            case "GPL":
                return "lpg"
            case "Metano":
                return "methane"
            case "Elettrica":
                return "electric"
            case "Ibrida":
                return "hybrid"
            default:
                return "gasoline"
        }
    }

    const mapTransmissionMode = (transmissionMode) => {
        // ["sequential-automatic", "automatic", "manual"]
        switch (transmissionMode) {
            case "Automatico":
                return "automatic"
            case "Manuale":
                return "manual"
            case "Sequenziale":
                return "sequential-automatic"
            default:
                return "manual"
        }
    }

    const mapGhost = (ghost) => {
        // convert 0 to false and 1 to true
        return ghost === 1
    }

    const uploadFile = async (file) => {
        try {
            const parseResults = await new Promise((resolve, reject) => {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete: resolve,
                    error: reject
                });
            });

            setTotalCarInCsv(parseResults.data.length)
            setShowUploadProgress(true)

            console.log(parseResults.data);

            const allManufacturers = await axios.get('/manufacturer');

            let totalCarUploaded = 0;

            for (const car of parseResults.data) {
                totalCarUploaded++;
                setTotalCarUploaded(totalCarUploaded);

                let manufacturerId;

                // Check if manufacturer already exists
                const existingManufacturer = allManufacturers.data.items.find(m => m.name === car['Marca']);
                if (existingManufacturer) {
                    manufacturerId = existingManufacturer.id;
                } else {
                    const manufacturerResponse = await axios.post('manufacturer', { name: car['Marca'] });
                    manufacturerId = manufacturerResponse.data.id;
                }

                const vehicleModelResponse = await axios.post('vehicleModel', {
                    manufacturer: { id: manufacturerId },
                    name: car['Modello/Gamma']
                });

                const vehicleResponse = await axios.post('vehicle', {
                    model: { id: vehicleModelResponse.data.id },
                    category: car['Categoria'],
                    drive: mapTraction(car['Trazione']),
                    seats: car['Posti'],
                    doors: car['Porte'],
                    novice_driver: car['Neopatentati'],
                    //overall_length_in_mm: car['Lunghezza'],
                    //overall_width_in_mm: car['Larghezza'],
                    //overall_height_in_mm: car['Altezza'],
                    front_image: car['Foto frontale'],
                    back_image: car['Foto posteriore'],
                    side_image: car['Foto laterale'],
                    version: car['Versione'],
                    engine: {
                        displacement_in_ccm: car['Cilindrata'],
                        fuelType: mapFuelType(car['Alimentazione']),
                        transmission_mode: mapTransmissionMode(car['Cambio']),
                        max_power_in_hp: car['Cv'],
                        euro: car['Euro'],
                        avg_consumption: car['Consumo medio (l/100km)'],
                        //name: car['Versione motore'],
                    }
                });

                const dealerVehicleResponse = await axios.post('dealerVehicle', {
                    dealer: {
                        id: dealer.id
                    },
                    vehicle: { id: vehicleResponse.data.id },
                    color: car['Colore'],
                    color_code: car['Codice colore'],
                    rent_price: Number(car['Prezzo noleggio']),
                    price_currency: 'euro',
                    km_made: 0,
                    insurance_from: car['Assicurazione da'] ? car['Assicurazione da'] : '2023-03-08T16:00:00.000Z',
                    insurance_to: car['Assicurazione a'] ? car['Assicurazione a'] : '2023-04-08T16:00:00.000Z',
                    vin: car['Telaio'] ? car['Telaio'] : 'Unavailable',
                    plate: car['Targa'],
                    ghost: mapGhost(car['Ghost']),
                    available_from: car['Disponibile da'],
                    available_to: car['Disponibile fino'],
                    min_drive_level: 'base',
                    extra_km_cost_on_100_km_basis: Number(car['Costo extra km']),
                    fi_franchise: Number(car['Franchigia FI']),
                    kasko_franchise: Number(car['Franchigia Kasko']),
                    rca_franchise: Number(car['Franchigia RCA']),
                    included_monthly_km: Number(car['Km mensili inclusi'])
                }, { headers: { 'Authorization': 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6ImluZm9AZG9vdGJveC5pdCIsInN1YiI6IjY0MmMzNGRmOWYwMDc4MzM3YTNhNDNjOCIsImlhdCI6MTY5ODQyNDUxMCwiZXhwIjoxNjk5MDI5MzEwfQ.uGPwuUtqtl7_RsvG5i2GfsEYXhukkPCbKkIHLupMSCU' } });
            }
            setUploadDone(true);
            setShowUploadProgress(false)
        } catch (error) {
            setUploadErrors("Il file selezionato non è valido")
        }
    }

    useEffect(() => {
        if (anno) {
            axios.get('vehicleModel?year=' + anno).then(res => {
                console.log(res.data)
            })
        }
    }, [anno])

    useEffect(() => {
        axios.get('auth/profile').then(res => {
            setDealer(res.data.operator.dealers[0])
        })
    }, [])

    return (
        <>
            <div className='absolute top-3 right-3 z-[1000] w-30 h-30' onClick={() => {setShowAddCarModal(false); navigate("/")}}>
                <IconContext.Provider value={{ color: "#df7423", size: 30 }}>
                    <AiFillCloseCircle className='absolute top-0 right-0 cursor-pointer' onClick={() => setShowAddCarModal(false)} />
                </IconContext.Provider>
            </div>
            <div className='px-5 flex items-center flex-col w-full absolute top-0 left-0 bg-doot-white dark:bg-gray-800 shadow-md rounded-md z-[999]'>
                {
                    files.length > 0 ? (
                        <div className='px-5 py-4 flex flex-col items-center'>
                            <IconContext.Provider value={{ color: "#df7423", size: 30 }}>
                                <BsFillFileEarmarkBarGraphFill />
                            </IconContext.Provider>
                            <p className='font-semibold mt-1 dark:text-doot-white'>{files[0].path}</p>
                            {
                                !showUploadProgress ? (
                                    <>
                                        {
                                            uploadErrors ? (
                                                <>
                                                    <div className='flex flex-col items-center'>
                                                        <p className='text-red-600 my-4 font-semibold'>Errore: {uploadErrors}</p>
                                                        <button className="ml-2 transition duration-200 ease-in-out hover:shadow-lg px-4 py-2 font-medium tracking-wide text-white bg-[#df7423] rounded-lg" onClick={() => setFiles([])}>
                                                            Carica un altro file
                                                        </button>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    {
                                                        uploadDone ? (
                                                            <p className='text-doot-orange my-4'>Caricamento completato</p>
                                                        ) : (
                                                            <div className='flex justify-around mt-3'>
                                                                <button className="mr-2 transition duration-200 ease-in-out hover:shadow-lg px-4 py-2 font-medium tracking-wide text-white capitalize bg-gray-300 rounded-lg" onClick={() => { setFiles([]); setShowFileInput(false) }}>
                                                                    Annulla
                                                                </button>
                                                                <button className="ml-2 transition duration-200 ease-in-out hover:shadow-lg px-4 py-2 font-medium tracking-wide text-white capitalize bg-[#df7423] rounded-lg" onClick={() => uploadFile(files[0])}>
                                                                    Carica
                                                                </button>
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </>
                                ) : (

                                    <>
                                        <p className='mt-4'>Carico {totalCarUploaded} di {totalCarInCsv} auto</p>
                                        <div className="mb-4 w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
                                            <div className={`bg-doot-orange h-2.5 rounded-full`} style={{ width: `${(totalCarUploaded / totalCarInCsv) * 100}%` }}></div>
                                        </div>
                                    </>
                                )
                            }
                        </div>
                    ) : (
                        <>
                            {
                                false && (
                                    <>
                                        <p className='mt-4 z-[50]'>Anno:</p>
                                        <button className="z-[50] px-6 py-2 font-medium tracking-wide text-white capitalize bg-[#df7423] rounded-lg flex items-center" onClick={() => setShowAnnoList(!showAnnoList)}>
                                            {anno ? anno : "Seleziona anno"}
                                            <div className='inline-block ml-2'>
                                                {
                                                    showAnnoList ? (
                                                        <IconContext.Provider value={{ color: "white", size: 15 }}>
                                                            <AiOutlineCaretUp />
                                                        </IconContext.Provider>
                                                    ) : (
                                                        <IconContext.Provider value={{ color: "white", size: 15 }}>
                                                            <AiOutlineCaretDown />
                                                        </IconContext.Provider>
                                                    )
                                                }
                                            </div>
                                        </button>
                                        <div className='z-[54] relative'>
                                            {
                                                showAnnoList && (
                                                    <div className="absolute -translate-x-1/2 z-10 bg-white rounded-lg shadow dark:bg-gray-700">
                                                        <ul className="h-48 px-10 pb-3 !mb-0 overflow-y-auto text-sm text-gray-700 dark:text-gray-200">
                                                            {
                                                                years.map((year, index) => (
                                                                    <li key={index}>
                                                                        <div className={`flex items-center cursor-pointer pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600 ${anno === year && "bg-gray-100 dark:bg-gray-600"}`} onClick={() => { setAnno(year); setShowAnnoList(false) }}>
                                                                            <p className="w-full py-2 text-center text-sm font-medium text-gray-900 rounded dark:text-gray-300">{year}</p>
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        {
                                            anno && (
                                                <>
                                                    <p className='mt-3'>Marca:</p>
                                                    <button className="px-6 py-2 font-medium tracking-wide text-white capitalize bg-[#df7423] rounded-lg flex items-center" onClick={() => setShowManufacturerList(!showManufacturerList)}>
                                                        {manufacturer ? manufacturer : "Seleziona marca"}
                                                        <div className='inline-block ml-2'>
                                                            {
                                                                showManufacturerList ? (
                                                                    <IconContext.Provider value={{ color: "white", size: 15 }}>
                                                                        <AiOutlineCaretUp />
                                                                    </IconContext.Provider>
                                                                ) : (
                                                                    <IconContext.Provider value={{ color: "white", size: 15 }}>
                                                                        <AiOutlineCaretDown />
                                                                    </IconContext.Provider>
                                                                )
                                                            }
                                                        </div>
                                                    </button>
                                                </>
                                            )
                                        }
                                        <p className='z-[50]'>Oppure</p>
                                    </>
                                )
                            }

                            <p className='my-4 z-[50] dark:text-white'><span className='cursor-pointer underline underline-offset-1 text-doot-orange' onClick={() => setShowFileInput(true)}>Carica</span> un file csv con le auto da caricare (puoi trascinarlo qui)</p>
                            {
                                !showFileInput && (
                                    <div className={`z-[10] absolute w-full h-full opacity-0 flex items-center justify-center dark:!bg-gray-800`} {...getRootProps({ style })}>
                                        <input {...getInputProps()} />
                                        {
                                            isDragAccept && (
                                                <p className=''>Rilascia qui il file CSV o clicca per selezionarlo</p>
                                            )
                                        }
                                        {
                                            isDragReject && (
                                                <p>Il file deve essere in formato CSV</p>
                                            )
                                        }
                                    </div>
                                )
                            }
                            {
                                showFileInput && (
                                    <>
                                        <div className={`z-[50] w-full h-full flex items-center justify-center dark:!bg-gray-800`} {...getRootProps({ style })}>
                                            <input {...getInputProps()} />
                                            <p>Rilascia qui il file CSV o clicca per selezionarlo</p>
                                        </div>
                                        <p className='z-[50] text-doot-orange mt-1 cursor-pointer' onClick={() => setShowFileInput(false)}>Annulla</p>
                                    </>
                                )
                            }
                        </>
                    )

                }

            </div>
        </>
    )
}

export default AddCarModal