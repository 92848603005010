import React, { useState } from 'react'
import { SlOptionsVertical } from 'react-icons/sl'
import { Oval } from 'react-loader-spinner'

function Test() {

    const [loading, setLoading] = useState(true)
    const [hover, setHover] = useState(false)

    let id = 1
    let brand = "Tesla"
    let model = "Model 3"
    let color = "#df7423"
    let transmission = "Automatico"
    let image = "https://www.pngmart.com/files/22/Tesla-Model-3-PNG-Photo.png"
    let back_image = "https://www.pngmart.com/files/22/Tesla-Model-3-PNG-Photo.png"
    let range = "450"
    let km = "10000"
    let ghost = false
    let current_use = "5"
    let out = true

    return (
        <div className='w-full right-0 h-screen rounded-lg bg-white'>
            <div className='px-4'>

                <div className='w-full mb-2'>
                    <div className='flex justify-between border-b-2 border-gray-100 py-4'>
                        <div className='flex justify-start items-center'>
                            <div className="mr-5 font-bold text-lg">#329865</div>
                            <span class="bg-green-100 text-green-800 text-md font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Attivo</span>
                        </div>
                        <div className='flex items-center'>
                            <div className=' bg-[#df7423] mr-5 text-white rounded-lg py-2 px-3 flex justify-center items-center cursor-pointer hover:scale-110' onClick={() => console.log('ciao')}>V3dj1</div>
                            <button className=''><SlOptionsVertical /></button>
                        </div>
                    </div>
                </div>


                <div className='w-[80%] flex mx-auto'>

                    <div class="w-[50%] bg-white border-2 border-gray-100 rounded-lg dark:bg-gray-800 dark:border-gray-700">
                        <div class="hidden flex justify-end px-4 pt-4">
                            <button id="dropdownButton" data-dropdown-toggle="dropdown" class="inline-block text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:focus:ring-gray-700 rounded-lg text-sm p-1.5" type="button">
                                <span class="sr-only">Open dropdown</span>
                                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 3">
                                    <path d="M2 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm6.041 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM14 0a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z" />
                                </svg>
                            </button>
                            <div id="dropdown" class="z-10 hidden text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                                <ul class="py-2" aria-labelledby="dropdownButton">
                                    <li>
                                        <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Edit</a>
                                    </li>
                                    <li>
                                        <a href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Export Data</a>
                                    </li>
                                    <li>
                                        <a href="#" class="block px-4 py-2 text-sm text-red-600 hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-200 dark:hover:text-white">Delete</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="flex flex-col items-center py-10">
                            <img class="w-24 h-24 mb-3 rounded-full shadow-lg" src="https://flowbite.com/docs/images/people/profile-picture-3.jpg" alt="Bonnie image" />
                            <h5 class="mb-1 text-xl font-medium text-gray-900 dark:text-white">Bonnie Green</h5>
                            <span class="text-sm text-gray-500 dark:text-gray-400">Visual Designer</span>
                            <div class="flex mt-4 space-x-3 md:mt-6">
                                <div className=' bg-[#df7423] text-white rounded-lg py-2 px-3 flex justify-center items-center cursor-pointer hover:scale-110' onClick={() => console.log('ciao')}>Contatta</div>
                            </div>
                        </div>
                    </div>

                    <div className='w-[50%] bg-doot-white dark:bg-gray-800 border-2 border-gray-200 dark:border-gray-700 shadow-xl rounded-md p-4 mx-2 mb-4'>
                        <div className='flex justify-between items-center'>
                            <p className='text-xl text-black dark:text-white'>{brand}</p>
                            <div className='text-[#df7423] cursor-pointer' onClick={() => { window.location.href = '/car/' + id }}>Dettagli</div>
                        </div>
                        <p className='text-black dark:text-white'>{model}</p>
                        <p className='uppercase font-semibold text-black dark:text-white'>{transmission}</p>
                        <div className='flex justify-center items-center'>
                            {
                                loading && (
                                    <Oval
                                        height={50}
                                        width={50}
                                        color="#df7423"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={loading}
                                        ariaLabel='oval-loading'
                                        secondaryColor="#d9a075"
                                        strokeWidth={3}
                                        strokeWidthSecondary={3}
                                    />
                                )
                            }
                            <div className='relative'>
                                <div className={'absolute top-0 right-0 w-5 h-5 rounded-full z-[50] shadow-sm'} style={{ backgroundColor: color }}></div>
                                <img src={image} className={`relative top-0 scale-125 transition-opacity ${loading ? 'hidden' : 'block'}`} alt={brand + " " + model} onLoad={() => setLoading(false)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} />
                                <div className={`absolute top-0 z-[8] w-full h-full bg-doot-white dark:bg-gray-800 transition-opacity ease-in-out duration-300 ${hover ? 'opacity-1' : 'opacity-0'}`}></div>
                                <img src={back_image} className={`absolute top-0 z-[10] scale-125 transition-opacity ease-in-out duration-300 ${loading ? 'hidden' : 'block'} ${hover ? 'opacity-1' : 'opacity-0'}`} alt={brand + " " + model} onLoad={() => setLoading(false)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} />
                            </div>
                        </div>
                        <div className='flex justify-start items-center w-full'>
                            <p className='whitespace-nowrap mr-2 font-semibold text-black dark:text-white'>{range} range</p>
                            <div className='border-solid border-b-2 border-gray-200 w-full'></div>
                        </div>
                        <div className='flex justify-between items-center mt-2'>
                            <div className='mr-2'>
                                <p className='font-semibold text-lg text-black dark:text-white'>{km}</p>
                                <p className='text-black dark:text-white'>Km</p>
                            </div>
                            <div className='mr-2'>
                                <p className='font-semibold text-lg text-black dark:text-white'>{ghost ? "Si" : "No"}</p>
                                <p className='text-black dark:text-white'>Ghost</p>
                            </div>
                            <div>
                                <p className='font-bold text-lg text-black dark:text-white'>{current_use} <span className='text-sm'>settimane</span></p>
                                <p className='text-black dark:text-white'>Utilizzo attuale</p>
                            </div>
                        </div>
                        {
                            !ghost && (
                                <div className='flex justify-center items-center mt-4'>
                                    <div className={`w-1/2 flex justify-center items-center border-2 border-gray-200 dark:border-gray-500 ${out ? "bg-[#df7423] text-white" : "text-black dark:text-white"}`}>
                                        <p className=''>OUT</p>
                                    </div>
                                    <div className={`w-1/2 flex justify-center items-center border-2 border-gray-200 dark:border-gray-500 ${out ? "text-black dark:text-white" : "bg-[#df7423] text-white"}`}>
                                        <p className=''>IN</p>
                                    </div>
                                </div>
                            )
                        }
                    </div>

                </div>


            </div>
        </div>
    )
}

export default Test