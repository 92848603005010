import React from 'react'
import icon from "../../images/icon.png"

function Loader() {
    return (
        <div className='w-screen h-scren bg-white'>
            <img src={icon} className="animate-pulse absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-2/12" alt="Dootbox icon" />
        </div>
    )
}

export default Loader