import React, { useState } from 'react'
import Table from './NewTable'
import { AiOutlineSearch } from "react-icons/ai"
import SingleUser from './SingleUser'

function Content({ userId }) {

    return (
        <>
            <div className={`flex w-full flex-col bg-doot-white dark:bg-gray-700 justify-around pt-4 mb-6 rounded-md`}>
                <div className="w-full border-b border-gray-200 pl-6 hidden">
                    <div className="w-4/12 relative flex items-center my-2">
                        <span className="absolute pl-3">
                            <AiOutlineSearch />
                        </span>

                        <input type="text" placeholder="Cerca" className="block w-full py-2.5 text-gray-700 placeholder-gray-400/70 bg-doot-white border border-gray-200 rounded-lg pl-11 pr-5 rtl:pr-11 rtl:pl-5 focus:border-blue-400 dark:focus:border-blue-300 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40" />
                    </div>
                </div>

                {
                    userId ? (
                        <SingleUser id={userId} />
                    ) : (
                        <Table />
                    )
                }

            </div>
        </>
    )
}

export default Content