import React from 'react'

function Footer() {

	let currentYear = new Date().getFullYear()

	return (
		<div className='flex justify-between items-center mt-6 text-gray-500'>
			<p>Copyright © {currentYear} Dootbox S. R. L. All Rights Reserved.</p>
			<a href='https://www.dootbox.it/chat' target='_blank' className='text-doot-orange hover:text-doot-orange-dark'>Contattaci</a>
		</div>
	)
}

export default Footer