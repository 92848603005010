import { useHookstate, hookstate } from "@hookstate/core"

const initialState = hookstate({
    id: null,
    name: null,
    surname: null,
    email: null,
    drive_level: null,
    role: null,
    documents: [],
    current_dealer: 0,
    __v: null
})

const useUserState = () => {
    const state = useHookstate(initialState)

    return({
        get: () => state.value,
        setUser: (user) => {
            state.merge(user)
        },
        setCurrentDealer: (dealer) => {
            state.current_dealer.set(dealer)
        },
        getCurrentDealer: () => state.current_dealer.value
    })
}

export default useUserState;