import React from 'react'
import { AiOutlineLogout, AiOutlinePhone } from "react-icons/ai"
import { IoGrid, IoHomeSharp, IoKey } from "react-icons/io5"
import { IconContext } from "react-icons"
import logo from '../../images/logo.png'
import { useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import axios from 'axios'

function Sidebar() {

    const location = useLocation()
    const navigate = useNavigate()
    const cookies = new Cookies()

    const logout = () => {
        axios.defaults.headers['Authorization'] = 'Bearer ' + cookies.get('access_token')
        cookies.remove('access_token', { path: '/' })
        navigate("/login")
    }

    return (
        <div className="hidden md:block relative bg-white">
            <div className="flex flex-col sm:flex-row sm:justify-around">
                <div className="w-72 h-screen">
                    <div className="flex flex-col items-center justify-left mx-6 mt-10">
                        <img className="h-10" src={logo} alt="Logo" />
                    </div>
                    <nav className="mt-10 px-6 ">
                        <div className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600 rounded-lg cursor-pointer" onClick={() => navigate('/')}>
                            <div className='w-8 h-8 rounded-md mr-2' style={{ backgroundColor: location.pathname === "/" ? "#df7423" : "" }}></div>
                            <IconContext.Provider value={{ className: "w-6 h-6" }}>
                                <IoHomeSharp />
                            </IconContext.Provider>
                            <span className="mx-4 text-lg font-normal">
                                Home
                            </span>
                            <span className="flex-grow text-right">
                            </span>
                        </div>
                        <div className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-800 rounded-lg cursor-pointer" onClick={() => navigate('/cars')}>
                            <div className='w-8 h-8 rounded-md mr-2' style={{ backgroundColor: location.pathname === "/cars" ? "#df7423" : "" }}></div>
                            <IconContext.Provider value={{ className: "w-6 h-6" }}>
                                <IoGrid />
                            </IconContext.Provider>
                            <span className="mx-4 text-lg font-normal">
                                Auto
                            </span>
                            <span className="flex-grow text-right">
                            </span>
                        </div>
                        <div className="hover:text-gray-800 hover:bg-gray-100 flex items-center p-2 my-6 transition-colors duration-200  text-gray-600 rounded-lg cursor-pointer" onClick={() => navigate('/rentals')}>
                            <div className='w-8 h-8  rounded-md mr-2' style={{ backgroundColor: location.pathname === "/rentals" ? "#df7423" : "" }}></div>
                            <IconContext.Provider value={{ className: "w-6 h-6" }}>
                                <IoKey />
                            </IconContext.Provider>
                            <span className="mx-4 text-lg font-normal">
                                Noleggi
                            </span>
                            <span className="flex-grow text-right">
                            </span>
                        </div>
                    </nav>
                    <div className="absolute bottom-0 my-10">
                        <a className="text-gray-600 hover:text-gray-800 transition-colors duration-200 flex items-center py-2 px-8" href="https://www.dootbox.it/chat" rel="noreferrer" target='_blank'>
                            <IconContext.Provider value={{ className: "w-6 h-6" }}>
                                <AiOutlinePhone />
                            </IconContext.Provider>
                            <span className="mx-4 font-medium">
                                Contattaci
                            </span>
                        </a>
                        <div className="text-gray-600 hover:text-gray-800 transition-colors duration-200 flex items-center py-2 px-8 cursor-pointer" onClick={() => logout()}>
                            <IconContext.Provider value={{ className: "w-6 h-6" }}>
                                <AiOutlineLogout />
                            </IconContext.Provider>
                            <span className="mx-4 font-medium">
                                Esci
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Sidebar