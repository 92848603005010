import React from "react"
import { useParams } from "react-router-dom"
import Content from "../components/rentals/Content"

function Rentals(props) {

	const { id } = useParams()
	const rentId = id ? id : null

	return (
		<Content rentId={rentId} />
	)
}

export default Rentals