import React, { useState } from 'react'
import { IconContext } from "react-icons"
import { AiOutlineUser, AiOutlineMenu, AiOutlineClose } from "react-icons/ai"
import { IoGrid, IoHomeSharp, IoKey } from "react-icons/io5"
import useUserState from '../../states/User'
import { useNavigate, useLocation } from 'react-router-dom'

function Topbar() {

	const userState = useUserState()
	const user = userState.get()
	const [showMenu, setShowMenu] = useState(false)
	const navigate = useNavigate()
	const location = useLocation()

	return (
		<div className='w-full sticky top-0 z-[999999] bg-white shadow-sm py-4 px-3'>
			<div className="flex justify-between md:justify-end items-center">
				{showMenu ? (
					<div className='block md:hidden' onClick={() => setShowMenu(false)}>
						<IconContext.Provider value={{ color: "black", size: 25 }}>
							<AiOutlineClose onClick={() => setShowMenu(false)} />
						</IconContext.Provider>
					</div>
				) : (
					<div className='block md:hidden' onClick={() => setShowMenu(true)}>
						<IconContext.Provider value={{ color: "black", size: 25 }}>
							<AiOutlineMenu />
						</IconContext.Provider>
					</div>
				)}
				<div className="w-fit flex justify-center items-center cursor-pointer">
					<div className="flex flex-col justify-center items-end">
						<p className='font-semibold text-lg text-black'>{user.name} {user.surname}</p>
						<p className='text-sm underline text-gray-500 cursor-pointer text-right'>{user.email}</p>
					</div>
					<div className="rounded-full p-3 bg-[#df7423] ml-3 cursor-pointer">
						<IconContext.Provider value={{ color: "white", size: 25 }}>
							<AiOutlineUser />
						</IconContext.Provider>
					</div>
				</div>
			</div>
			{showMenu && (
				<div className='mt-5 flex justify-center'>
					<div className="mobile-instance" style={{ backgroundColor: location.pathname === "/" ? "#df7423" : "", color: location.pathname === "/" ? "white" : "" }} onClick={() => navigate('/')}>
						<IconContext.Provider value={{ className: "w-6 h-6" }}>
							<IoHomeSharp />
						</IconContext.Provider>
						<span className="mx-4 text-lg font-normal">
							Home
						</span>
					</div>
					<div className="mobile-instance" style={{ backgroundColor: location.pathname === "/cars" ? "#df7423" : "", color: location.pathname === "/cars" ? "white" : "" }} onClick={() => navigate('/cars')}>
						<IconContext.Provider value={{ className: "w-6 h-6" }}>
							<IoGrid />
						</IconContext.Provider>
						<span className="mx-4 text-lg font-normal">
							Auto
						</span>
					</div>
					<div className="mobile-instance" style={{ backgroundColor: location.pathname === "/rentals" ? "#df7423" : "", color: location.pathname === "/rentals" ? "white" : "" }} onClick={() => navigate('/rentals')}>
						<IconContext.Provider value={{ className: "w-6 h-6" }}>
							<IoKey />
						</IconContext.Provider>
						<span className="mx-4 text-lg font-normal">
							Noleggi
						</span>
					</div>
				</div>
			)}
		</div>
	)
}

export default Topbar