import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './style.css'

import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import bootstrapPlugin from '@fullcalendar/bootstrap';
import itLocale from '@fullcalendar/core/locales/it';
import { addHours, format } from 'date-fns';
import axios from '../../api';

function renderEventContent(eventContent) {
	const { timeText, event } = eventContent;
	return (
		<>
			{timeText && (
				<>
					<div className="fc-daygrid-event-dot"></div>
					<div className="fc-event-time">{timeText}</div>
				</>
			)}
			<div className="fc-event-title">{event.title}</div>
		</>
	);
}

function Calendar() {

	const [EVENTS, setEVENTS] = useState([])

	useEffect(() => {
		axios.get('/rental').then((response) => {
			response.data.items.map((rental, index) => {
				setEVENTS((prev) => {
					return [...prev, {
						id: rental.id,
						title: 'Ritiro: '+rental.user.name + ' ' + rental.user.surname,
						start: format(new Date(rental.date_from), 'yyyy-MM-dd') + 'T' + format(new Date(rental.date_from), 'HH:mm:ss'),
						end: format(new Date(rental.date_from), 'yyyy-MM-dd') + 'T' + format(addHours(new Date(rental.date_from), 1), 'HH:mm:ss'),
					}]
				})
				setEVENTS((prev) => {
					return [...prev, {
						id: rental.id,
						title: 'Rinnovo: ' + rental.user.name + ' ' + rental.user.surname,
						start: format(new Date(rental.date_to), 'yyyy-MM-dd') + 'T' + format(new Date(rental.date_to), 'HH:mm:ss'),
						end: format(new Date(rental.date_to), 'yyyy-MM-dd') + 'T' + format(addHours(new Date(rental.date_to), 1), 'HH:mm:ss'),
					}]
				})
				if (rental.return_date){
					setEVENTS((prev) => {
						return [...prev, {
							id: rental.id,
							title: 'Riconsegna: ' + rental.user.name + ' ' + rental.user.surname,
							start: format(new Date(rental.return_date), 'yyyy-MM-dd') + 'T' + format(new Date(rental.return_date), 'HH:mm:ss'),
							end: format(new Date(rental.return_date), 'yyyy-MM-dd') + 'T' + format(addHours(new Date(rental.return_date), 1), 'HH:mm:ss'),
						}]
					})
				}
			})
		}).catch((error) => {
			console.log(error)
		})
	}, []);

	function handleEventClick(clickInfo) {
		console.log(clickInfo.event.id)
		window.location.href = '/rental/' + clickInfo.event.id
	}

	return (
		<div className='w-[95%] h-fit mx-auto'>
			<FullCalendar
				plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, bootstrapPlugin]}
				headerToolbar={{
					left: 'prev,next today',
					center: 'title',
					right: 'dayGridMonth,timeGridWeek,timeGridDay'
				}}
				initialView="dayGridMonth"
				eventContent={renderEventContent}
				themeSystem='bootstrap'
				height='auto'
				locale={itLocale}
				events={EVENTS}
				eventClick={handleEventClick}
			/>
		</div>
	);
};

export default Calendar;