import React from 'react'
import { AiFillQuestionCircle, AiOutlineArrowRight, AiFillCar } from 'react-icons/ai'
import { HiUser } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'

function Card(props) {

    const navigate = useNavigate()

    return (
        <>
            <div class="w-full bg-doot-white border-2 border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 px-4 py-4 mb-6 cursor-pointer" onClick={() => navigate('/rental/' + props.id)}>
				<div className="w-full flex flex-row">
					<div className="mr-3 font-bold text-black dark:text-white">#{props.id}</div>
					<span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">{props.status}</span>
				</div>
				<div className="w-full flex flex-row justify-center items-center my-4">
					<div className="w-[40%] flex justify-center">
						<img class="" src={props.foto} alt="" />
					</div>
					<div className="w-[59%]">
						<p className="font-normal text-gray-700 dark:text-gray-400">{props.marca}</p>
						<p className="font-bold text-2xl text-gray-900 dark:text-white">{props.modello}</p>
						<p className="font-bold text-md text-gray-700 dark:text-gray-400 underline">{props.cognome_cliente} {props.nome_cliente}</p>
					</div>
				</div>
				<div className="border-t-2 border-gray-600 border-dashed flex flex-row justify-between pt-2">
					<div>
						<p className="font-bold text-gray-500 dark:text-gray-400">INIZIO</p>
						<p className="font-bold text-gray-900 dark:text-white">{props.data_inizio[0]}, <span className="text-gray-500 dark:text-gray-400">{props.data_inizio[1]}</span></p>
					</div>
					<div className="">
						<p className="font-bold text-gray-500 dark:text-gray-400 text-right">FINE</p>
						<p className="font-bold text-gray-900 dark:text-white text-right">{props.data_fine[0]}, <span className="text-gray-500 dark:text-gray-400">{props.data_fine[1]}</span></p>
					</div>
				</div>
			</div>
        </>
    )
}

export default Card