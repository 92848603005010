import React, { useState, useEffect } from 'react'
import axios from '../../api'
import { Oval } from 'react-loader-spinner'
import { IconContext } from "react-icons"
import { AiOutlineLeft, AiOutlineCloseCircle } from "react-icons/ai"
import ImgWithLoader from './ImgWithLoader'
import { useNavigate } from "react-router-dom"

function SingleUser({ id }) {

    const navigate = useNavigate()

    const [user, setUsers] = useState({})
    const [load, setLoad] = useState(false)
    const [error, setError] = useState(false)
    const [focus, setFocus] = useState({ "document": "", "side": "" })

    const mapType = (type) => {
        if (type === 'driving_license') return "Patente"
        if (type === 'identity_card') return "Carta d'identità"
    }

    const formatDate = (date) => {
        const d = new Date(date)
        return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`
    }

    useEffect(() => {
        axios.get('user/' + id)
            .then(res => {
                setUsers(res.data)
                setLoad(true)
            })
            .catch(err => {
                console.log(err)
                setError(false)
            })
    }, [])

    if (error) {
        return (
            <div className="container flex flex-col items-center justify-center w-full mx-auto bg-white rounded-lg shadow py-36">
                <p className="text-center text-gray-500 text-lg">
                    Qualcosa è andato storto
                </p>
                <div>
                    Torna alla <a href="/users" className="text-[#df7423] hover:text-[#c2641d]">pagina degli utenti</a>
                </div>
            </div>
        )
    }

    return (
        <>
            {
                !load ? (
                    <div className='flex flex-row justify-center items-center p-4'>
                        <Oval
                            height={20}
                            width={20}
                            color="#df7423"
                            wrapperStyle={{}}
                            wrapperClass=""
                            ariaLabel='oval-loading'
                            secondaryColor="#d9a075"
                            strokeWidth={3}
                            strokeWidthSecondary={3}
                        />
                    </div>
                ) : (

                    <div className='w-full flex flex-col bg-white  py-14 mb-6 rounded-md p-12 flex-wrap relative'>
                        {focus.document ? (
                            <>
                                <div className='flex w-full mb-12'>
                                    <div className='bg-[#df7423] rounded-lg py-2 px-3 flex justify-center items-center cursor-pointer hover:scale-110' onClick={() => { setFocus({ 'document': '', 'side': '' }) }}>
                                        <IconContext.Provider value={{ className: "w-6 h-6", color: "white" }}>
                                            <AiOutlineCloseCircle />
                                        </IconContext.Provider>
                                    </div>
                                </div >
                                <ImgWithLoader url={user.documents[focus.document][focus.side]} />
                            </>
                        ) : (
                            <>
                                <div className='flex w-full mb-12'>
                                    <div className='bg-gray-300 rounded-lg py-2 px-3 flex justify-center items-center cursor-pointer hover:scale-110' onClick={() => navigate(-1)}>
                                        <AiOutlineLeft /> Indietro
                                    </div>
                                </div>
                                <h1 className="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl">{user.name} {user.surname}</h1>
                                <a href={"mailto:" + user.email} className="text-lg text-gray-800 lg:text-xl">{user.email} ({user.email_verified ? "Verificata" : "Non verificata"})</a>
                                <p className="max-w-lg mt-4 text-gray-500">
                                    Telefono: {user.mobile_phone ? user.mobile_phone : "Non inserito"} ({user.mobile_phone_verified ? "Verificato" : "Non verificato"})
                                </p>
                                <p className="max-w-lg mt-4 text-gray-500">
                                    Codice fiscale: {user.tax_code ? user.tax_code.toUpperCase() : "Non inserito"}
                                </p>

                                {
                                    user.documents.length == 0 ? (
                                        <h1 className="text-2xl font-semibold text-gray-800 lg:text-3xl mt-6">Non ci sono documenti</h1>
                                    ) : (
                                        <>
                                            {Object.keys(user.documents).map((index, item) => (
                                                <section key={index} className="bg-white">
                                                    <div className="container py-10">
                                                        <div className="">
                                                            <h1 className="text-2xl font-semibold text-gray-800 capitalize lg:text-3xl">{mapType(user.documents[item].type)}:</h1>

                                                            <p className="max-w-lg mt-4 text-gray-500">
                                                                {user.documents[item].name} {user.documents[item].surname}
                                                            </p>
                                                            <p className="max-w-lg mt-4 text-gray-500">
                                                                Rilasciato da: {user.documents[item].released_by}
                                                            </p>
                                                            <p className="max-w-lg mt-4 text-gray-500">
                                                                Valido da: {formatDate(user.documents[item].valid_from)} a {formatDate(user.documents[item].valid_to)}
                                                            </p>
                                                        </div>

                                                        <div className="grid grid-cols-1 gap-8 mt-8 lg:grid-cols-2">
                                                            <div className="cursor-pointer shadow-md rounded-md" onClick={() => setFocus({ 'document': index, 'side': 'front_image' })}>
                                                                <ImgWithLoader url={user.documents[item].front_image} />

                                                                <div className="relative z-20 w-full p-6 mx-auto bg-white border-t-2">
                                                                    <a href="#" className="font-semibold text-gray-800 hover:underline md:text-xl">
                                                                        Fronte
                                                                    </a>
                                                                </div>
                                                            </div>

                                                            <div className="cursor-pointer shadow-md rounded-md">
                                                                <ImgWithLoader url={user.documents[item].back_image} setFocus={setFocus} index={index} />

                                                                <div className="relative z-20 w-full p-6 mx-auto bg-white border-t-2">
                                                                    <a href="#" className="font-semibold text-gray-800 hover:underline md:text-xl">
                                                                        Retro
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            ))}
                                        </>
                                    )
                                }

                            </>
                        )}
                    </div>

                )
            }
        </>
    )
}

export default SingleUser