import React, { useState, useEffect } from 'react'
import { Oval } from 'react-loader-spinner'
import { Buffer } from 'buffer'
import axios from '../../api'
import { AiOutlineDownload } from "react-icons/ai"

function ImgWithLoader({ url, setFocus, index }) {
    const [loader, setLoader] = useState(true)
    const [src, setSrc] = useState(null)

    const fetchImage = async (url) => {
        let url_path = url?.replace("http://api.dootbox.it/api/", "").replace("https://api.dootbox.it/api/", "").replace("http://", "https://").replace(" ", "")
        console.log(url_path)
        let img = await axios.get(url_path, { responseType: 'arraybuffer' })
        let src_data = `data:${img.headers['content-type']};base64,${Buffer.from(img.data, 'binary').toString('base64')}`
        setSrc(src_data)
        setLoader(false)
    }

    useEffect(() => {
        fetchImage(url)
    }, [url])

    return (
        <>
            {loader ? (
                <Oval
                    height={30}
                    width={30}
                    color="#df7423"
                    wrapperStyle={{}}
                    wrapperClass="justify-center m-20"
                    visible={loader}
                    ariaLabel='oval-loading'
                    secondaryColor="#d9a075"
                    strokeWidth={3}
                    strokeWidthSecondary={3}
                />
            ) : (
                <>
                    {
                        url ? (
                            <>
                                <img src={src} className="relative z-10 object-contain w-full rounded-md h-96" onClick={() => setFocus({ 'document': index, 'side': 'back_image' })} />
                                <div className='flex justify-end px-10 pb-3'>
                                    <div className='bg-[#df7423] w-fit h-fit rounded-lg p-2'>
                                        <a href={src} download className="text-white"><AiOutlineDownload /></a>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <img src="https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/legacy-fre-image-placeholder-it-1-1674070998.png?crop=0.5xw:1xh;center,top&resize=640:*" className="relative z-10 object-cover w-full rounded-md h-96" />
                        )
                    }
                </>
            )}
        </>
    )
}

export default ImgWithLoader