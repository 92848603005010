import React, { useEffect, useState } from 'react'
import axios from '../../api'
import { AiOutlineLeft, AiOutlineEdit } from "react-icons/ai"
import { BiGhost } from "react-icons/bi"
import { mapTransmission } from '../../utils/WordMapper'
import { useNavigate } from 'react-router-dom'
import { Oval } from 'react-loader-spinner'
import nophoto from '../../images/no_photo.png'

function SingleCar({ id }) {

    const navigate = useNavigate()

    const [data, setData] = useState([])
    const [selectedCar, setSelectedCar] = useState()
    const [load, setLoad] = useState(false)

    function formatDate(dateString) {
        const date = new Date(dateString)
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, "0")
        return `${year}/${month}`
    }

    function formatDateInsurance(dateString) {
        const date = new Date(dateString)
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, "0")
        const day = date.getDate().toString().padStart(2, "0")
        return `${year}/${month}/${day}`
    }

    useEffect(() => {
        axios.get(`dealerVehicle/${id}`).then(res => {
            setData(res.data)
            setSelectedCar(res.data.front_image)
            setLoad(true)
        })
    }, [])

    return (
        <>
            {
                !load ? (
                    <>
                        <div className='flex flex-row justify-center items-center p-4'>
                            <Oval
                                height={20}
                                width={20}
                                color="#df7423"
                                wrapperStyle={{}}
                                wrapperClass=""
                                ariaLabel='oval-loading'
                                secondaryColor="#d9a075"
                                strokeWidth={3}
                                strokeWidthSecondary={3}
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className='w-full flex flex-col bg-doot-white dark:bg-gray-700 justify-around py-14 mb-6 rounded-md items-center flex-wrap relative'>
                            <div className='w-[90%] h-fit flex flex-wrap justify-start items-center'>
                                <div className='flex w-full'>
                                    <div className='bg-gray-700 dark:bg-gray-600 text-white rounded-lg py-2 px-3 flex justify-center items-center cursor-pointer hover:scale-110' onClick={() => navigate(-1)}>
                                        <AiOutlineLeft /> Indietro
                                    </div >
                                    <div className='bg-[#df7423] dark:bg-doot-orange-dark text-white ml-4 rounded-lg py-2 px-3 flex justify-center items-center cursor-pointer hover:scale-110' onClick={() => console.log('ciao')}>
                                        <AiOutlineEdit /> Modifica
                                    </div>
                                </div >

                                <div className='w-2/3 lg:w-full lg:m-auto lg:flex lg:flex-row lg:justify-center mt-3'>
                                    <div className='lg:w-[50%]'>
                                        <img src={selectedCar ? selectedCar : nophoto} onError={(e) => e.target.src = nophoto} className='border-2 border-gray-200 rounded-lg' alt='car' />
                                    </div>
                                </div>

                                <div className='w-1/3 lg:w-[50%] lg:m-auto lg:mt-3 lg:flex lg:flex-row lg:items-center lg:justiy-center pl-4'>
                                    <div className='lg:mr-4 relative'>
                                        <img src={data.front_image + "?versionId=" + (Math.random() + 1).toString(36).substring(7)} onError={(e) => e.target.src = nophoto} onClick={() => setSelectedCar(data.front_image + "?versionId=" + (Math.random() + 1).toString(36).substring(7))} className={`cursor-pointer mb-2 border-2 border-gray-200 rounded-lg ${data.vehicle.front_image === selectedCar && 'scale-105'}`} alt='car' />
                                    </div>
                                    <div className='lg:mr-4 relative'>
                                        <img src={data.back_image + "?versionId=" + (Math.random() + 1).toString(36).substring(7)} onError={(e) => e.target.src = nophoto} onClick={() => setSelectedCar(data.back_image + "?versionId=" + (Math.random() + 1).toString(36).substring(7))} className={`cursor-pointer mb-2 border-2 border-gray-200 rounded-lg ${data.vehicle.back_image === selectedCar && 'scale-105'}`} alt='car' />
                                    </div>
                                    <div className='relative'>
                                        <img src={data.side_image + "?versionId=" + (Math.random() + 1).toString(36).substring(7)} onError={(e) => e.target.src = nophoto} onClick={() => setSelectedCar(data.side_image + "?versionId=" + (Math.random() + 1).toString(36).substring(7))} className={`cursor-pointer mb-2 border-2 border-gray-200 rounded-lg ${data.vehicle.side_image === selectedCar && 'scale-105'}`} alt='car' />
                                    </div>
                                </div>

                                <div className='w-full pl-4'>
                                    <h2 className='uppercase text-2xl font-semibold flex flex-row items-center dark:text-doot-white'><span className='mr-2'>{data.vehicle.model.manufacturer.name}</span> <span className='relative'><span className={`${!data.ghost && 'after:content-["/"] after:absolute after:-top-1/4 after:left-1/3 after:scale-110 after:rotate-2'}`}><BiGhost /></span></span></h2>
                                    <h3 className='font-semibold text-lg dark:text-doot-white'>{data.vehicle.model.name}</h3>
                                    <h2 className='dark:text-doot-white'>{data.dealer.name}</h2>
                                    <div className='border-solid border-b-2 border-gray-200 flex justify-between mb-3 mt-3'>
                                        <p className="dark:text-doot-white font-semibold">{mapTransmission(data.vehicle.engine.transmission_mode)}</p>
                                        <p className='font-semibold first-letter:capitalize dark:text-doot-white'>{data.status}</p>
                                    </div>
                                    <div className='w-full flex justify-start flex-wrap'>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{formatDate(data.vehicle.production_from)}</p>
                                            <p className="dark:text-doot-white">Immatricolazione</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{data.vehicle.drive}</p>
                                            <p className="dark:text-doot-white">Trazione</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className='font-semibold dark:text-doot-white'>{data.km_made ? data.km_made : "?"}</p>
                                            <input type='text' placeholder={data.km_made} className='hidden w-[80%] border-2 border-gray-200 rounded-lg p-1 focus:border-[#df7423] focus:outline-none focus:ring focus:ring-transparent' />
                                            <p className="dark:text-doot-white">km</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{data.vehicle.engine.fuelType}</p>
                                            <p className="dark:text-doot-white">Alimentazione</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{data.vehicle.segment}</p>
                                            <p className="dark:text-doot-white">Segmento</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{data.vehicle.category}</p>
                                            <p className="dark:text-doot-white">Categoria</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{data.color}</p>
                                            <input type='text' placeholder={data.km_made} className='hidden w-[80%] border-2 border-gray-200 rounded-lg p-1 focus:border-[#df7423] focus:outline-none focus:ring focus:ring-transparent' />
                                            <p className="dark:text-doot-white">Colore</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{data.min_drive_level}</p>
                                            <input type='text' placeholder={data.km_made} className='hidden w-[80%] border-2 border-gray-200 rounded-lg p-1 focus:border-[#df7423] focus:outline-none focus:ring focus:ring-transparent' />
                                            <p className="dark:text-doot-white">Livello minimo</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{data.fi_franchise}%</p>
                                            <input type='text' placeholder={data.km_made} className='hidden w-[80%] border-2 border-gray-200 rounded-lg p-1 focus:border-[#df7423] focus:outline-none focus:ring focus:ring-transparent' />
                                            <p className="dark:text-doot-white">Franchigia</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{data.kasko_franchise}</p>
                                            <input type='text' placeholder={data.km_made} className='hidden w-[80%] border-2 border-gray-200 rounded-lg p-1 focus:border-[#df7423] focus:outline-none focus:ring focus:ring-transparent' />
                                            <p className="dark:text-doot-white">Franchigia kasko</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{data.rca_franchise}</p>
                                            <p className="dark:text-doot-white">Rca franchigia</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{data.plate}</p>
                                            <p className="dark:text-doot-white">Targa</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{data.rent_price}€</p>
                                            <p className="dark:text-doot-white">Prezzo</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{data.vehicle.avg_consumption} l/100km</p>
                                            <p className="dark:text-doot-white">Consumi</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{data.vehicle.doors}</p>
                                            <p className="dark:text-doot-white">Porte</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{data.vehicle.novice_driver ? "Si" : "No"}</p>
                                            <p className="dark:text-doot-white">Neopatentati</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{data.vehicle.seats}</p>
                                            <p className="dark:text-doot-white">Posti</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{data.extra_km_cost_on_100_km_basis}€/km</p>
                                            <p className="dark:text-doot-white">Costo extra</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{formatDateInsurance(data.insurance_from)}</p>
                                            <p className="dark:text-doot-white">Assicurata da</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold">{formatDateInsurance(data.insurance_to)}</p>
                                            <p className="dark:text-doot-white">Assicurata fino a</p>
                                        </div>
                                        <div className='w-1/2 mb-4'>
                                            <p className="dark:text-doot-white font-semibold"></p>
                                            <p className="dark:text-doot-white"></p>
                                        </div>
                                    </div>
                                </div>

                            </div >
                            <div className='hidden w-[90%]'>
                                <p className="dark:text-doot-white">Descrizione:</p>
                                <p className="dark:text-doot-white"></p>
                            </div>
                        </div >
                    </>
                )
            }
        </>

    )
}

export default SingleCar