import React from 'react'
import SingleRent from './SingleRent'
import List from './List'

function Content({ rentId }) {
	return (
		<>
			{
				rentId ? (
					<SingleRent id={rentId} />
				) : (
					<List />
				)}
		</>
	)
}

export default Content