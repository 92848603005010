import React, { useEffect, useState } from "react"
import logo from '../../images/logo.png'
import { IconContext } from "react-icons"
import { AiOutlineUser } from "react-icons/ai"
import { IoGrid, IoHomeSharp, IoKey } from "react-icons/io5"
import { BsMoonStarsFill, BsFillSunFill } from "react-icons/bs"
import { FaUserGroup } from "react-icons/fa6"
import { HiChevronUpDown } from "react-icons/hi2"
import { BiLogOut } from "react-icons/bi"
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import useUserState from '../../states/User'
import Cookies from 'universal-cookie'
import axios from 'axios'

function Layout({ isDarkMode, setIsDarkMode }) {

	const location = useLocation()
	const navigate = useNavigate()
	const userState = useUserState()
	const user = userState.get()
	const current_dealer = userState.getCurrentDealer()
	const cookies = new Cookies()

	const [showDealerList, setShowDealerList] = useState(false)

	const changeCurrentDealer = (index) => {
		userState.setCurrentDealer(index)
		console.log(location.pathname.split("/")[1])
		if (location.pathname.split("/")[1] === "car") navigate('/cars')
		else if (location.pathname.split("/")[1] === "cars") navigate('/cars')
		else if (location.pathname.split("/")[1] === "rental") navigate('/rentals')
		else if (location.pathname.split("/")[1] === "user") navigate('/users')
		else if (location.pathname.split("/")[1] === "users") navigate('/users')
		else navigate('/')
	}

	const logout = () => {
		axios.defaults.headers['Authorization'] = 'Bearer ' + cookies.get('access_token')
		cookies.remove('access_token', { path: '/' })
		navigate("/login")
	}

	return (
		<div className="">
			<nav className="fixed top-0 z-50 w-full bg-doot-white border-b border-gray-200 dark:!bg-gray-800 dark:border-gray-700">
				<div className="px-3 py-3 lg:px-5 lg:pl-3">
					<div className="flex items-center justify-between">
						<div className="flex items-center justify-start">
							<a href="" className="flex ml-2 md:mr-24">
								<img src={logo} className="h-8 mr-3" alt="Dootbox Logo" />
							</a>
						</div>
						<div className="flex items-center">
							<div className="flex items-center ml-3">
								<div className={`relative flex justify-center items-end mr-3 px-2 py-2 rounded-md ${user.operator.dealers.length > 1 && "hover:bg-gray-200 cursor-pointer"}`} onMouseEnter={() => setShowDealerList(true)} onMouseLeave={() => setShowDealerList(false)}>
									<p className='text-sm underline text-gray-500 dark:text-doot-white text-right !mb-0'>{user.operator.dealers[current_dealer].name}</p>
									{
										// if dealers are more than 1, show the chevron
										user.operator.dealers.length > 1 && (
											<>
												{
													!isDarkMode ? (
														<IconContext.Provider value={{ color: "gray", size: 20 }}>
															<HiChevronUpDown />
														</IconContext.Provider>
													) : (
														<IconContext.Provider value={{ color: "white", size: 20 }}>
															<HiChevronUpDown />
														</IconContext.Provider>
													)
												}
											</>
										)
									}
									<div className={`absolute top-3 z-50 my-4 ${showDealerList ? "block" : "hidden"} text-base list-none bg-doot-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600`}>
										{
											// loop through dealers except the current one
											user.operator.dealers.map((dealer, index) => {
												if (index !== current_dealer) {
													return (
														<div key={index} className="px-4 py-3" role="none" onClick={() => changeCurrentDealer(index)}>
															<p className="text-sm text-gray-900 dark:text-white" role="none">
																{dealer.name}
															</p>
															<p className="text-sm font-medium text-gray-900 truncate dark:text-gray-300" role="none">
																{dealer.email}
															</p>
														</div>
													)
												}
											})
										}
									</div>
								</div>
								<div className="cursor-pointer mr-4 dark:text-white" onClick={() => setIsDarkMode(!isDarkMode)}>
									{
										!isDarkMode ? (
											<IconContext.Provider value={{ color: "black", size: 20 }}>
												<BsMoonStarsFill />
											</IconContext.Provider>
										) : (
											<IconContext.Provider value={{ color: "white", size: 20 }}>
												<BsFillSunFill />
											</IconContext.Provider>
										)
									}
								</div>
								<div className="flex flex-col justify-center items-end">
									<p className='font-semibold text-lg text-black dark:text-white !mb-0'>{user.name} {user.surname}</p>
									<p className='text-sm underline text-gray-500 dark:text-gray-300 cursor-pointer text-right !mb-0'>{user.email}</p>
								</div>
								<div>
									<div className="rounded-full p-2 bg-[#df7423] ml-3 cursor-pointer">
										<IconContext.Provider value={{ color: "white", size: 15 }}>
											<AiOutlineUser />
										</IconContext.Provider>
									</div>
								</div>
								{/* USER OPTIONS */}
								<div className="z-50 hidden my-4 text-base list-none bg-doot-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600" id="dropdown-user">
									<div className="px-4 py-3" role="none">
										<p className="text-sm text-gray-900 dark:text-white" role="none">
											Neil Sims
										</p>
										<p className="text-sm font-medium text-gray-900 truncate dark:text-gray-300" role="none">
											neil.sims@flowbite.com
										</p>
									</div>
									<ul className="py-1" role="none">
										<li>
											<a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Dashboard</a>
										</li>
										<li>
											<a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Settings</a>
										</li>
										<li>
											<a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Earnings</a>
										</li>
										<li>
											<a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white" role="menuitem">Sign out</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</nav>

			<div id="logo-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform -translate-x-full bg-doot-white border-r border-gray-200 sm:translate-x-0 dark:!bg-gray-800 dark:border-gray-700" aria-label="Sidebar">
				<div className="relative h-full px-3 pt-2 pb-4 overflow-y-auto bg-doot-white dark:!bg-gray-800">
					<ul className="space-y-2 font-medium">
						<li>
							<div className={`flex items-center p-2 text-gray-600 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700 ${location.pathname === "/" && "bg-gray-200 dark:bg-gray-700"} group cursor-pointer`} onClick={() => navigate('/')}>
								<IconContext.Provider value={{ className: "w-6 h-6 text-gray-600 dark:text-white" }}>
									<IoHomeSharp />
								</IconContext.Provider>
								<span className="ml-3">Dashboard</span>
							</div>
						</li>
						<li>
							<div className={`flex items-center p-2 text-gray-600 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700 ${(location.pathname === "/cars" || location.pathname.split("/")[1] === "car") && "bg-gray-200 dark:bg-gray-700"} group cursor-pointer`} onClick={() => navigate('/cars')}>
								<IconContext.Provider value={{ className: "w-6 h-6 text-gray-600 dark:text-white" }}>
									<IoGrid />
								</IconContext.Provider>
								<span className="flex-1 ml-3 whitespace-nowrap">Auto</span>
							</div>
						</li>
						<li>
							<div className={`flex items-center p-2 text-gray-600 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700 ${(location.pathname === "/rentals" || location.pathname.split("/")[1] === "rental") && "bg-gray-200 dark:bg-gray-700"} group cursor-pointer`} onClick={() => navigate('/rentals')}>
								<IconContext.Provider value={{ className: "w-6 h-6 text-gray-600 dark:text-white" }}>
									<IoKey />
								</IconContext.Provider>
								<span className="flex-1 ml-3 whitespace-nowrap">Noleggi</span>
								{/*<span className="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">3</span>*/}
							</div>
						</li>
						<li>
							<div className={`flex items-center p-2 text-gray-600 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700 ${(location.pathname === "/users" || location.pathname.split("/")[1] === "user") && "bg-gray-200 dark:bg-gray-700"} group cursor-pointer`} onClick={() => navigate('/users')}>
								<IconContext.Provider value={{ className: "w-6 h-6 text-gray-600 dark:text-white" }}>
									<FaUserGroup />
								</IconContext.Provider>
								<span className="flex-1 ml-3 whitespace-nowrap">Clienti</span>
								{/*<span className="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">3</span>*/}
							</div>
						</li>
					</ul>
					<ul className="absolute bottom-0 space-y-2 font-medium">
						<li>
							<div className={`flex items-center p-2 text-gray-600 rounded-lg dark:text-white hover:bg-gray-200 dark:hover:bg-gray-700 group cursor-pointer`} onClick={() => logout()}>
								<IconContext.Provider value={{ className: "w-6 h-6 text-gray-600 dark:text-white" }}>
									<BiLogOut />
								</IconContext.Provider>
								<span className="flex-1 ml-3 whitespace-nowrap">Logout</span>
								{/*<span className="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">3</span>*/}
							</div>
						</li>
					</ul>
				</div>
			</div>

			<div className="p-4 sm:ml-64">
				<div className="p-4 mt-14">
					<Outlet />
				</div>
			</div>

		</div>

	)
}

export default Layout