import React, { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from '../../api';
import useUserState from '../../states/User';
import Loader from './Loader';

const ProtectedRoutes = () => {

    const cookies = new Cookies()
    const token = cookies.get('access_token')
    const userState = useUserState()
    const [auth, setAuth] = useState(null)

    useEffect(() => {
        if (token) {
            if (userState.get().id !== null) {
                setAuth(true)
            } else {
                axios.get('auth/profile', { headers: { 'Authorization': 'Bearer ' + token } }).then(res => {
                    axios.defaults.headers['Authorization'] = 'Bearer ' + token
                    userState.setUser(res.data)
                    setAuth(true)
                })
                .catch(() => {
                    setAuth(false)
                })
            }
        } else {
            setAuth(false)
        }
    }, [token, userState])

    if (auth === null) {
        return <Loader />
    } else {
        return (
            auth ? <Outlet /> : <Navigate to="/login" />
        )
    }
}

export default ProtectedRoutes