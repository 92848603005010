import { Routes, Route } from "react-router-dom";
import { useState } from "react";
import ProtectedRoutes from "./components/common/ProtectedRoutes";
import Home from "./pages/Home";
import Stats from "./pages/Stats";
import Login from "./pages/Login";
import Cars from "./pages/Cars";
import Rentals from "./pages/Rentals";
import Users from "./pages/Users";
import Components from "./pages/Components";
import Test from "./pages/Test";
import Layout from "./components/common/Layout";

// kowf9w05hf

export default function App() {

	const [isDarkMode, setIsDarkMode] = useState(false);

	return (
		<div className={isDarkMode ? 'dark' : ''}>
			<div className="bg-gray-100 dark:bg-gray-900 min-h-screen">
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route element={<ProtectedRoutes />}>
						<Route element={<Layout isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />}>
							<Route path="/" element={<Home />} />
							<Route path="/stats" element={<Stats />} />
							<Route path="/cars" element={<Cars />} />
							<Route path="/car/:id" element={<Cars />} />
							<Route path="/rentals" element={<Rentals />} />
							<Route path="/rental/:id" element={<Rentals />} />
							<Route path="/users" element={<Users />} />
							<Route path="/users/:id" element={<Users />} />
							<Route path="/components" element={<Components />} />
							<Route path="/test" element={<Test />} />
						</Route>
					</Route>
				</Routes>
			</div>
		</div>
	);
}