import React, { useEffect, useState, useRef } from 'react'
import { Oval } from 'react-loader-spinner'
import Row from './NewRow'
import axios from '../../api'
import { BsSearch } from "react-icons/bs"
import useUserState from '../../states/User'

function NewTable() {

    const [users, setUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState()
    const [load, setLoad] = useState(false)
    const searchInput = useRef()
    const [searchState, setSearchState] = useState(false)
    const userState = useUserState()
    const user = userState.get()
    const current_dealer = userState.getCurrentDealer()

    const updateFilteredUsers = () => {
        setLoad(false)
        let filtered = []
        for (let i = 0; i < users.length; i++) {
            filtered.push(users[i])
        }
        setFilteredUsers(filtered)
        setLoad(true)
    }

    const userStatus = (user) => {
        if (user.documents.length == 0) return false
        if (user.drive_level && user.documents[0].status == "valid") return true
        else return false
    }

    const search = () => {
        if (searchState) {
            searchInput.current.value = ''
            setFilteredUsers(users)
            setSearchState(false)
            return
        }
        setLoad(false)
        // search on the name, surname, email
        let filtered = []
        for (let i = 0; i < users.length; i++) {
            if (users[i].name.toLowerCase().includes(searchInput.current.value.toLowerCase())) filtered.push(users[i])
            else if (users[i].surname.toLowerCase().includes(searchInput.current.value.toLowerCase())) filtered.push(users[i])
            else if (users[i].email.toLowerCase().includes(searchInput.current.value.toLowerCase())) filtered.push(users[i])
        }
        setFilteredUsers(filtered)
        setSearchState(true)
        setLoad(true)
    }

    useEffect(() => {
        setLoad(false)
        axios.get('/rental').then((response) => {
            response.data.items.sort((a, b) => {
                if (a.user.documents.length === 0 && b.user.documents.length === 0) return 0
                if (a.user.documents.length === 0) return 1
                if (b.user.documents.length === 0) return -1
                // if "updated_at" is not present put before the users without document but after the users that have the key "updated_at"
                if (!a.user.documents[0].updated_at) return 1
                if (!b.user.documents[0].updated_at) return -1
                return new Date(b.user.documents[0].updated_at) - new Date(a.user.documents[0].updated_at)
            })
            setUsers(response.data.items)
            // set users only if the rent is from current dealer
            //setUsers(response.data.items.filter((item) => item.dealerVehicle.dealer.id === user.operator.dealers[current_dealer].id).map((item) => item.user))
            setFilteredUsers(response.data.items)
            //setFilteredUsers(response.data.items.filter((item) => item.dealerVehicle.dealer.id === user.operator.dealers[current_dealer].id).map((item) => item.user))
            setLoad(true)
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    return (
        <>
            {
                !load || !filteredUsers ? (
                    <div className='flex flex-row justify-center items-center p-4'>
                        <Oval
                            height={20}
                            width={20}
                            color="#df7423"
                            wrapperStyle={{}}
                            wrapperClass=""
                            ariaLabel='oval-loading'
                            secondaryColor="#d9a075"
                            strokeWidth={3}
                            strokeWidthSecondary={3}
                        />
                    </div>
                ) : (
                    <>
                        <div className='flex justify-between mt-1 mb-3'>
                            <h1 className="text-2xl font-bold pl-4 text-black dark:text-doot-white text-left">Utenti ({filteredUsers.filter((rent) => rent.dealerVehicle.dealer.id === user.operator.dealers[current_dealer].id).length})</h1>
                            <div className='mr-5 flex flex-row'>
                                <input type='text' name='search' placeholder='Cerca' className='block w-full placeholder-gray-400/70 rounded-l-lg border border-gray-200 bg-doot-white dark:bg-gray-100 px-3 py-2 text-gray-700 focus:border-[#df7423] focus:outline-none focus:ring focus:ring-[#df7423] focus:ring-opacity-40' ref={searchInput} />
                                <button onClick={() => search()} className='px-3 py-2 font-medium tracking-wide text-doot-white capitalize transition-colors duration-300 transform bg-[#df7423] rounded-r-lg hover:bg-[#bd611b] focus:outline-none focus:ring focus:ring-[#df7423] focus:ring-opacity-80'>{searchState ? 'x' : (
                                    <BsSearch />
                                )}</button>
                            </div>
                        </div>
                        <div className="pb-3 container flex flex-col items-center justify-center w-full mx-auto bg-doot-white dark:bg-gray-700 rounded-lg shadow mt-0 !pt-0">
                            {
                                filteredUsers.filter((rent) => rent.dealerVehicle.dealer.id === user.operator.dealers[current_dealer].id).length === 0 ? (
                                    <p>Non ci sono utenti</p>
                                ) : (
                                    <ul className="w-full flex flex-col divide-y divide mt-0 !pt-0">
                                        {
                                            filteredUsers.map((rent, index) => {
                                                if (rent.dealerVehicle.dealer.id === user.operator.dealers[current_dealer].id)
                                                    return (
                                                        <Row key={index} index={index} user={rent.user} />
                                                    )
                                            })
                                        }
                                    </ul>
                                )
                            }
                        </div>
                    </>
                )
            }
        </>
    )
}

export default NewTable