import React, { useState } from 'react'
import user_img from '../../images/user.png'
import { MdVerified } from "react-icons/md"
import { IconContext } from "react-icons"
import { useNavigate } from "react-router-dom"

function NewRow({ index, user }) {

    const navigate = useNavigate()

    const [hover, setHover] = useState(false)

    const userStatus = () => {
        if (user.documents.length == 0) return false
        if (user.drive_level && user.documents[0].status == "valid") return true
        else return false
    }

    const mapType = (type) => {
        if (type === 'driving_license') return "Patente"
        if (type === 'identity_card') return "Carta d'identità"
    }

    return (
        <>
            <li className={`flex flex-row ${hover ? "bg-[#F2F5F5] dark:bg-gray-800" : "bg-doot-white dark:bg-gray-700"}`} onClick={() => navigate('/users/'+user.id)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
                <div className="flex items-center justify-between flex-1 p-4 cursor-pointer select-none">
                    <div className='flex justify-start items-center w-[40%] mr-5 text-black'>
                        <div className="flex flex-col items-center justify-center w-10 h-10 mr-4">
                            <a href="#" className="relative block">
                                <img alt="profil" src={user_img} className="mx-auto object-contain rounded-full h-10 w-10 dark:bg-doot-white" />
                            </a>
                        </div>
                        <div className="flex-1 pl-1 mr-10">
                            <div className="font-medium text-left text-gray-600 dark:text-doot-white">
                                {user.name} {user.surname}
                            </div>
                            <div className="text-sm text-gray-600 dark:text-doot-white flex flew-row items-center">
                                {user.email}
                                <span>
                                    {user.email_verified && (
                                        <IconContext.Provider value={{ className: "w-3 h-3 ml-1 text-green-500", color: "#df7423" }}>
                                            <MdVerified />
                                        </IconContext.Provider>
                                    )}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="hidden flex-2 text-xs text-gray-600 dark:text-doot-white mr-10 w-[25%] justify-center">
                        <div className="flex items-center gap-x-2">
                            {user.documents.length !== 0 ?
                                user.documents.map((doc, index) =>
                                (
                                    <div key={index} className="flex items-center gap-x-1">
                                        <p className="px-3 py-1 text-xs text-doot-white rounded-full bg-[#df7423]">{mapType(doc.type)}</p>
                                    </div>
                                )
                                ) : (
                                    <p className="px-3 py-1 text-xs text-gray-500 rounded-full bg-gray-100/60">Nessun documento</p>
                                )
                            }
                        </div>
                    </div>
                    <div className="flex justify-end text-xs text-gray-600 dark:text-doot-white w-[35%]">
                        {userStatus() ? (
                            <div className="hidden lg:inline-flex items-center px-3 py-1 rounded-full gap-x-2 bg-emerald-100/60 dark:bg-emerald-500/40">
                                <span className="h-1.5 w-1.5 rounded-full bg-emerald-500"></span>
                                <h2 className="text-sm font-normal text-emerald-500 !m-0">Attivo</h2>
                            </div>
                        ) : (
                            <div className="hidden lg:inline-flex items-center px-3 py-1 rounded-full gap-x-2 bg-red-100/60 dark:bg-red-500/40">
                                <span className="h-1.5 w-1.5 rounded-full bg-red-500"></span>
                                <h2 className="text-sm font-normal text-red-500 !m-0">Non attivo</h2>
                            </div>
                        )}
                        <button className="flex justify-end w-fit lg:w-24 text-right bg-transparent shadow-none">
                            <svg width="20" fill="currentColor" height="20" className="text-gray-500 dark:text-doot-white hover:text-gray-800" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                                </path>
                            </svg>
                        </button>
                    </div>
                </div>
            </li>
        </>
    )
}

export default NewRow