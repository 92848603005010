import React from 'react'
import Topbar from '../common/Topbar'
import Footer from '../common/Footer'
import Calendar from './Calendar'

function Content() {

	return (

		<div className='w-full flex flex-col bg-doot-white dark:bg-gray-700 justify-around py-4 mb-6 rounded-md'>
			<Calendar />
		</div>

	)
}

export default Content