import React, { useEffect, useState } from 'react'
import axios from '../../api'
import { Oval } from 'react-loader-spinner'
import user_img from '../../images/user.png'
import { BiRightArrowAlt } from 'react-icons/bi'
import { IconContext } from 'react-icons'
import { IoKey } from "react-icons/io5"
import { useNavigate } from 'react-router-dom'
import AuthIFrame from '../../utils/AuthIFrame'
import Cookies from 'universal-cookie'
import { useLocation } from 'react-router-dom'

function SingleRent({ id }) {

    const navigate = useNavigate()
    const cookies = new Cookies()
    const location = useLocation()

    const [rent, setRent] = useState()
    const [loading, setLoading] = useState(true)

    const formatDate = (date) => {
        let d = new Date(date)
        return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
    }

    const mapStatus = (status) => {
        if (status === 'pending') return 'In attesa'
        if (status === 'active') return 'Attivo'
        if (status === 'rejected') return 'Rifiutato'
        return status
    }

    const returnApprove = () => {
        // ask for confirmation with console.confirm and if true make a request to the server
        if (window.confirm('Sei sicuro di voler confermare il rientro del veicolo?')) {
            axios.patch('/rental/' + id + '/return-approve', { checked_return_date: rent.return_date }).then((response) => {
                console.log(response.data)
                setRent(response.data)
            })
        }
    }

    useEffect(() => {
        console.log(location.pathname.split("/")[1])
        axios.get('/rental/' + id).then((response) => {
            setRent(response.data)
            setLoading(false)
        })
    }, [])

    return (
        <>
            {
                loading ? (
                    <Oval
                        height={20}
                        width={20}
                        color="#df7423"
                        wrapperStyle={{}}
                        wrapperclassName=""
                        ariaLabel='oval-loading'
                        secondaryColor="#d9a075"
                        strokeWidth={3}
                        strokeWidthSecondary={3}
                    />
                ) : (
                    <div>
                        <div className='w-full mb-5'>

                            <nav class="flex" aria-label="Breadcrumb">
                                <ol class="inline-flex items-center space-x-1 md:space-x-3">
                                    <li class="inline-flex items-center cursor-pointer">
                                        <div class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white" onClick={() => navigate('/rentals')}>
                                            <IconContext.Provider value={{ className: "w-6 h-6 mr-2 text-gray-600 dark:text-white" }}>
                                                <IoKey />
                                            </IconContext.Provider>
                                            Noleggi
                                        </div>
                                    </li>
                                    <li aria-current="page">
                                        <div class="flex items-center">
                                            <svg class="w-3 h-3 text-gray-400 mx-1" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                                            </svg>
                                            <span class="ml-1 text-sm font-medium text-gray-500 md:ml-2 dark:text-gray-400">#{rent.id}</span>
                                        </div>
                                    </li>
                                </ol>
                            </nav>

                        </div>
                        <div className='w-full rounded-lg shadow-lg bg-white'>
                            <div className='w-full rounded-t-lg flex flex-row justify-between items-center bg-gray-100 p-6 cursor-pointer shadow-md' onClick={() => navigate('/users/' + rent.user.id)}>
                                <div className='flex items-center'>
                                    <div className='mr-5'>
                                        <img src={user_img} className='rounded-full w-12 h-12 object-contain' alt={rent.user.name + ' ' + rent.user.surname} />
                                    </div>
                                    <div>
                                        <h1 className="text-xl font-bold text-gray-800">{rent.user.name} {rent.user.surname}</h1>
                                        <h3 className="text-lg text-gray-800">{rent.user.email}</h3>
                                    </div>
                                </div>
                                <div className=''>
                                    <svg width="20" fill="currentColor" height="20" className="text-gray-500 hover:text-gray-800" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                            <div className='w-full flex flex-row items-center justify-center px-6 py-10'>
                                <div className='w-1/3 -ml-10'>
                                    <img src={rent.dealerVehicle.side_image} alt={rent.dealerVehicle.vehicle.model.manufacturer.name + ' ' + rent.dealerVehicle.vehicle.model.name} />
                                </div>
                                <div className=''>
                                    <h1 className="text-xl font-bold text-gray-800">{rent.dealerVehicle.vehicle.model.manufacturer.name} {rent.dealerVehicle.vehicle.model.name}</h1>
                                    <h3 className="text-lg text-gray-800">{rent.dealerVehicle.dealer.name}</h3>
                                    <div className="flex mt-3 item-center">
                                        <h1 className="text-lg font-bold text-gray-700 md:text-xl mr-10">€ {rent.dealerVehicle.rent_price}</h1>
                                        <button className="px-2 py-1 text-xs font-bold text-white uppercase transition-colors duration-300 transform bg-gray-800 rounded hover:bg-gray-700 focus:outline-none focus:bg-gray-700" onClick={() => navigate('/car/' + rent.dealerVehicle.id)}>Dettagli</button>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full p-6 flex flex-row items-center justify-around'>
                                <h1 className='text-lg font-bold text-gray-800'>Stato noleggio: {mapStatus(rent.status)}</h1>
                                <div className='flex items-center justify-end'>
                                    <h1 className='text-lg font-bold text-gray-800'>Codice ritiro: </h1>
                                    <div className="ml-5 px-6 py-2 font-medium tracking-wide text-white capitalize bg-[#df7423] rounded-lg">
                                        {rent.code}
                                    </div>
                                </div>
                            </div>
                            <div className='w-full flex justify-around p-6 border-y-2 border-gray-100'>
                                <div>
                                    <p className='text-lg font-bold text-gray-800'>{formatDate(rent.date_from)}</p>
                                </div>
                                <div>
                                    <IconContext.Provider value={{ size: '1.5rem' }}>
                                        <BiRightArrowAlt />
                                    </IconContext.Provider>
                                </div>
                                <div>
                                    <p className='text-lg font-bold text-gray-800'>{formatDate(rent.date_to)}</p>
                                </div>
                            </div>
                            <div className='w-full p-6 flex flex-row items-center justify-center'>
                                <h1 className='text-lg font-bold text-gray-800'>Contratto:</h1>
                                <div className='flex items-center justify-end'>
                                    <div className="ml-5 px-6 py-2 font-medium tracking-wide text-white capitalize bg-[#df7423] rounded-lg" onClick={() => alert("Attualmente non funziona ma ci stiamo lavorando :)")}>
                                        Firma
                                    </div>
                                </div>
                            </div>

                            <div className='hidden'>
                            <AuthIFrame src={rent.contract.providerViewUrls.operator.replace("https://api.dootbox.it/api", "https://proxy-1-w4843165.deta.app/")} token={cookies.get('access_token')} />
                            </div>

                            {
                                rent.return_date && (
                                    <div className='w-full p-6 flex flex-row items-center justify-center'>
                                        <h1 className='text-lg font-bold text-gray-800'>Conferma riconsegna del veicolo:</h1>
                                        <div className='flex items-center justify-end'>
                                            <button className="ml-5 px-6 py-2 font-medium tracking-wide text-white capitalize bg-[#df7423] rounded-lg" onClick={() => returnApprove()}>
                                                Conferma
                                            </button>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )

            }
        </>
    )
}

export default SingleRent