import React from "react"

function Components({ isDarkMode, setIsDarkMode }) {

	return (
		<>
			<div class="w-full bg-doot-white border-2 border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 px-4 py-4">
				<div className="w-full flex flex-row">
					<div className="mr-3 font-bold">#329865</div>
					<span class="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">Attivo</span>
				</div>
				<div className="w-full flex flex-row justify-center items-center my-4">
					<div className="w-[40%] flex justify-center">
						<img class="" src="https://www.pngmart.com/files/22/Tesla-Model-3-PNG-Photo.png" alt="" />
					</div>
					<div className="w-[59%]">
						<p className="font-normal text-gray-700 dark:text-gray-400">Tesla</p>
						<p className="font-bold text-2xl text-gray-900 dark:text-white">Model 3</p>
						<p className="font-bold text-md text-gray-700 dark:text-gray-400 underline">Mario Rossi</p>
					</div>
				</div>
				<div className="border-t-2 border-gray-600 border-dashed flex flex-row justify-between pt-2">
					<div>
						<p className="font-bold text-gray-500 dark:text-gray-400">START</p>
						<p className="font-bold text-gray-900 dark:text-white">26 Feb, <span className="text-gray-500 dark:text-gray-400">2021</span></p>
					</div>
					<div className="">
						<p className="font-bold text-gray-500 dark:text-gray-400 text-right">END</p>
						<p className="font-bold text-gray-900 dark:text-white text-right">26 Feb, <span className="text-gray-500 dark:text-gray-400">2021</span></p>
					</div>
				</div>
			</div>
		</>
	)
}

export default Components