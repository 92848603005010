import React from "react"
import Sidebar from "../components/common/Sidebar"
import Content from "../components/stats/Content"

function Home() {

	return (
		<div className="w-screen h-screen flex flex-start">
			<Sidebar />
			<Content />
		</div>
	)
}

export default Home