import React from "react"
import { useParams } from "react-router-dom"
import Content from "../components/users/NewContent"

function Users({ isDarkMode, setIsDarkMode }) {

	const { id } = useParams()
	const userId = id ? id : null

	return (
		<Content userId={userId} isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
	)
}

export default Users