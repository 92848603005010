import React from "react"
import { useParams } from "react-router-dom"
import Content from "../components/cars/Content"

function Cars({ isDarkMode, setIsDarkMode }) {

	const { id } = useParams()
	const carId = id ? id : null

	return (
		<Content carId={carId} isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
	)
}

export default Cars