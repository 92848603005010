import React, { useEffect, useState } from 'react'
import axios from '../../api'
import { Oval } from 'react-loader-spinner'
import { useNavigate } from "react-router-dom"
import { BiRightArrowAlt } from 'react-icons/bi'
import { IconContext } from 'react-icons'
import Card from './NewCard'
import useUserState from '../../states/User'

function List({ }) {

    const navigate = useNavigate()
    const userState = useUserState()
    const user = userState.get()
    const dealer_id = userState.getCurrentDealer()

    const [rentals, setRentals] = useState([])
    const [loading, setLoading] = useState(true)

    const formatDate = (date) => {
        let d = new Date(date)
        return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
    }

    const newformatDate = (date) => {
        const monthsItalian = [
            "Gen", "Feb", "Mar", "Apr", "Mag", "Giu",
            "Lug", "Ago", "Set", "Ott", "Nov", "Dic"
        ];

        let d = new Date(date)

        // Extract day, month, and year from the Date object
        const day = d.getDate();
        const month = d.getMonth();
        const year = d.getFullYear();

        // Convert month number to Italian month name
        const monthName = monthsItalian[month];

        // Create the desired format
        return [`${day} ${monthName}`, year.toString()];
    }

    useEffect(() => {
        axios.get('/rental').then((response) => {
            setRentals(response.data)
            setLoading(false)
        })
    }, [])

    return (
        <>
            {
                loading ? (
                    <div>
                        <Oval
                            height={20}
                            width={20}
                            color="#df7423"
                            wrapperStyle={{}}
                            wrapperClass=""
                            ariaLabel='oval-loading'
                            secondaryColor="#d9a075"
                            strokeWidth={3}
                            strokeWidthSecondary={3}
                        />
                    </div>
                ) : (
                    <div className='max-w-[80%] lg:max-w-[60%] mx-auto'>
                        {
                            rentals.items.map((rental, index) => {
                                if (rental.dealerVehicle.dealer.id == user.operator.dealers[dealer_id].id)
                                    return (
                                        <div key={index}>
                                            <Card
                                                id={rental.id}
                                                status={rental.status}
                                                marca={rental.dealerVehicle.vehicle.model.manufacturer.name}
                                                modello={rental.dealerVehicle.vehicle.model.name}
                                                nome_cliente={rental.user.name}
                                                cognome_cliente={rental.user.surname}
                                                data_inizio={newformatDate(rental.date_from)}
                                                data_fine={newformatDate(rental.date_to)}
                                                foto={rental.dealerVehicle.front_image} />


                                            <div className='hidden'>
                                                <div className='flex flex-row items-center justify-between shadow bg-doot-white rounded-lg my-3 px-6 py-5 cursor-pointer' onClick={() => navigate('/rental/' + rental.id)}>
                                                    <div className='flex flex-row items-center w-full lg:w-[50%]'>
                                                        {
                                                            rental.status == 'active' ? (
                                                                <div className='w-3 h-3 rounded-full bg-green-400'></div>
                                                            ) : (
                                                                <div className='w-3 h-3 rounded-full bg-red-400'></div>
                                                            )
                                                        }
                                                        <div className='ml-6 w-[40%] text-black'>
                                                            <p>{rental.dealerVehicle.vehicle.model.manufacturer.name} {rental.dealerVehicle.vehicle.model.name}</p>
                                                            <p>{rental.user.name} {rental.user.surname}</p>
                                                        </div>
                                                        <div className='ml-8 w-[50%] text-black'>
                                                            <p className='flex flex-row items-center'>
                                                                {formatDate(rental.date_from)}
                                                                <span>
                                                                    <IconContext.Provider value={{ size: '1.2rem' }}>
                                                                        <BiRightArrowAlt />
                                                                    </IconContext.Provider>
                                                                </span>
                                                                {formatDate(rental.date_to)}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className='text-black'>{rental.dealerVehicle.name}</p>
                                                    </div>
                                                    <div>
                                                        <svg width="20" fill="currentColor" height="20" className="text-gray-500 hover:text-gray-800" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                            }
                            )
                        }
                    </div>
                )
            }
        </>
    )
}

export default List