import React from 'react'
import { useState } from 'react'
import logo from '../images/logo.png'
import axios from '../api'
import Cookies from 'universal-cookie'
import { useNavigate } from "react-router-dom"
import useUserState from '../states/User'
import { AiOutlineMessage } from "react-icons/ai"

function Login() {
    const cookies = new Cookies()
    const userState = useUserState()
    const navigate = useNavigate()
    const [errors, setErrors] = useState('')
    const [loading, setLoading] = useState(false)
    const [modalPassResetVisible, setModalPassResetVisible] = useState(false)
    const [emailSent, setEmailSent] = useState(false)
    const [resetPasswordError, setResetPasswordError] = useState('')
    const [modalLoading, setModalLoading] = useState(false)

    const [data, setData] = useState({
        username: "",
        password: ""
    });

    const [modalEmail, setModalEmail] = useState('')

    const { username, password } = data

    const changeHandler = e => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const closeModal = () => {
        setModalPassResetVisible(false)
        setResetPasswordError('')
        setEmailSent(false)
        setResetPasswordError('')
        setModalLoading(false)
        setModalEmail('')
    }

    const submitHandler = async (e) => {
        setLoading(true)
        setErrors('')
        e.preventDefault()
        await axios.post('auth', data).then(async (res) => {
            await axios.get('auth/profile', { headers: { 'Authorization': 'Bearer ' + res.data.access_token } }).then(response => {
                if (response.data.role === "operator") {
                    axios.defaults.headers['Authorization'] = 'Bearer ' + res.data.access_token
                    cookies.set('access_token', res.data.access_token, { path: '/' })
                    userState.setUser(response.data)
                    setLoading(false)
                    navigate("/")
                } else {
                    if (response.data.role === "admin") {
                        setErrors("Per accedere come amministratore utilizza il gestionale")
                        setLoading(false)
                    } else {
                        setErrors("Non sei autorizzato ad accedere. Le credenziali potrebbero essere errate oppure potresti non avere i permessi necessari")
                        setLoading(false)
                    }
                }
            }).catch(err => {
                setLoading(false)
                setErrors("Qualcosa è andato storto")
            })
        }).catch(err => {
            setLoading(false)
            setErrors("Qualcosa è andato storto")
        })
        setLoading(false)
    }

    const sendEmail = async () => {
        setModalLoading(true)
        setResetPasswordError('')
        await axios.post('auth/password-reset', { email: modalEmail }).then(res => {
            setModalLoading(false)
            setEmailSent(true)
            setResetPasswordError('')
        }).catch(err => {
            setModalLoading(false)
            // if error is 404 set error message to "email not found" else it's ok (dunno why)
            if (err.response.status === 404) {
                setResetPasswordError("Email non trovata")
            } else {
                setModalLoading(false)
                setEmailSent(true)
                setResetPasswordError('')
            }
        })
    }

    const needAssistance = () => {
        window.open("https://www.dootbox.it/chat", "_blank")
    }

    return (
        <>

            <div className="bg-white">
                <div className="flex justify-center h-screen">
                    <div className="hidden lg:block lg:w-[60%] bg-[url(https://images.pexels.com/photos/164634/pexels-photo-164634.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)] bg-center bg-cover">
                        <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-20">
                            <div>
                                <p className="max-w-xl mt-3 text-gray-300">
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-[40%]">
                        <div className="flex-1">
                            <div className="text-center">
                                <img src={logo} alt="Logo" />

                                <p className="mt-3 text-gray-500">Accedi al tuo account da Dealer</p>
                            </div>

                            <div className="mt-8">
                                <form onSubmit={submitHandler}>
                                    <div>
                                        <label htmlFor="email" className="block mb-2 text-sm text-gray-600">Email</label>
                                        <input type="email" name="username" id="email" placeholder="" value={username.toLowerCase()} onChange={changeHandler} className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    <div className="mt-6">
                                        <div className="flex justify-between mb-2">
                                            <label htmlFor="password" className="text-sm text-gray-600">Password</label>
                                            <p className="text-sm text-gray-400 focus:text-[#df7423] hover:text-[#df7423] hover:underline" onClick={() => setModalPassResetVisible(true)}>Password dimenticata?</p>

                                            {
                                                modalPassResetVisible && (
                                                    <>
                                                        <div className='fixed w-screen h-screen top-0 left-0 bg-black opacity-40 z-[99999]'></div>
                                                        <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-1/2 inline-block px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-lg sm:my-8 sm:align-middle sm:p-6 lg:p-14 z-[99999999]">
                                                            {
                                                                modalLoading ? (
                                                                    <div className='flex justify-center items-center w-full h-full'>
                                                                        <svg className="w-5 h-5 mx-auto -mb-1 text-doot-orange animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                                                                        </svg>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        {
                                                                            emailSent ? (
                                                                                <div>
                                                                                    <div className='w-full flex flex-col justify-center items-center'>
                                                                                        <p className='text-doot-orange'>Email inviata</p>
                                                                                        <p className="my-2 text-sm text-gray-500">Controlla nella casella di posta</p>
                                                                                        <button className="w-full px-4 py-2 mt-5 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-doot-orange rounded-md sm:mt-0 sm:w-1/2 sm:mx-2 hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40" onClick={() => closeModal()}>
                                                                                            Chiudi
                                                                                        </button>
                                                                                    </div>
                                                                                    <button className="w-full mt-5 px-4 py-2 flex justify-center items-center text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md sm:w-1/2 mx-auto hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40" onClick={() => needAssistance()}>
                                                                                        <span className='mr-3'>
                                                                                            <AiOutlineMessage />
                                                                                        </span>
                                                                                        Assistenza
                                                                                    </button>
                                                                                </div>
                                                                            ) : (
                                                                                <>
                                                                                    <div>
                                                                                        <div className="mt-4 text-center">
                                                                                            <h3 className="font-large text-xl leading-6 text-gray-800 capitalize" id="modal-title">
                                                                                                Recupera password
                                                                                            </h3>

                                                                                            <p className="mt-2 text-sm text-gray-500">
                                                                                                Inserisci la tua email qui sotto e ti invieremo le istruzioni per recuperarla. Nel caso in cui non ti ricordassi nessuna informazione per accedere, clicca sul pulsante "Assistenza".
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="mt-4">
                                                                                        <label className="text-sm text-gray-700" for="share link">Email</label>

                                                                                        <div className="flex items-center mt-2 -mx-1">
                                                                                            <input type="text" value={modalEmail ? modalEmail : username.toLocaleLowerCase()} onChange={(e) => setModalEmail(e.target.value)} className="flex-1 block h-10 px-4 mx-1 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" />
                                                                                        </div>
                                                                                        {
                                                                                            resetPasswordError && (
                                                                                                <p className='text-red-600'>{resetPasswordError}</p>
                                                                                            )
                                                                                        }
                                                                                    </div>

                                                                                    <div className="mt-4 sm:mt-6 sm:flex sm:items-center sm:-mx-2">
                                                                                        <button className="w-full px-4 py-2 flex justify-center items-center text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md sm:w-1/2 mx-auto hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40" onClick={() => closeModal()}>
                                                                                            Chiudi
                                                                                        </button>

                                                                                        <button className="w-full cursor-pointer px-4 py-2 mt-3 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-doot-orange rounded-md sm:mt-0 sm:w-1/2 sm:mx-2 hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40" onClick={() => sendEmail()}>
                                                                                            Recupera password
                                                                                        </button>
                                                                                    </div>
                                                                                    <button className="w-full mt-5 px-4 py-2 flex justify-center items-center text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md sm:w-1/2 mx-auto hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40" onClick={() => needAssistance()}>
                                                                                        <span className='mr-3'>
                                                                                            <AiOutlineMessage />
                                                                                        </span>
                                                                                        Assistenza
                                                                                    </button>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </>
                                                )
                                            }

                                        </div>

                                        <input type="password" name="password" id="password" placeholder="" value={password} onChange={changeHandler} className="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                                    </div>

                                    <div className="mt-6">
                                        {loading ? (
                                            <button className="cursor-not-allowed w-full px-4 py-2 tracking-wide text-white transition-colors duration-300 transform bg-[#df7423] rounded-md hover:bg-[#c2641d] focus:outline-none focus:bg-[#c2641d] focus:ring focus:ring-[#df7423] focus:ring-opacity-50">
                                                <svg className="w-5 h-5 mx-auto -mb-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                                                </svg>
                                            </button>
                                        ) : (
                                            <button type='submit' className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-300 transform bg-[#df7423] rounded-md hover:bg-[#c2641d] focus:outline-none focus:bg-[#c2641d] focus:ring focus:ring-[#df7423] focus:ring-opacity-50">
                                                Entra
                                            </button>
                                        )}
                                    </div>

                                    {errors && (
                                        <>
                                            <div className="my-4 text-md font-semibold text-red-600">{errors}</div>
                                            <div className="w-fit mt-5 px-4 py-2 tracking-wide text-white transition-colors duration-300 transform bg-[#df7423] rounded-md hover:bg-[#c2641d] focus:outline-none focus:bg-[#c2641d] focus:ring focus:ring-[#df7423] focus:ring-opacity-50 flex justify-left items-center" onClick={() => needAssistance()}>
                                                <AiOutlineMessage />
                                                <a href="mailto:info@dootbox.it" className="ml-2 text-white">Chiedi assistenza</a>
                                            </div>
                                        </>
                                    )}

                                </form>
                            </div>
                        </div>
                    </div >
                </div >
            </div >
        </>
    )
}

export default Login