import React, { useState } from 'react'
import { Oval } from 'react-loader-spinner'

function Card({ index, id, brand, model, color, transmission, image, back_image, range, km, ghost, current_use, out, setOpenDetails, setSelectedCar }) {

    const [loading, setLoading] = useState(true)
    const [hover, setHover] = useState(false)

    return (
        <div className='bg-doot-white dark:bg-gray-800 border-2 border-gray-200 dark:border-gray-700 shadow-xl rounded-md p-4 w-full md:w-[46%] lg:w-[30%] xl:w-[23%] mx-2 mb-4'>
            <div className='flex justify-between items-center'>
                <p className='text-xl text-black dark:text-white'>{brand}</p>
                <div className='text-[#df7423] cursor-pointer' onClick={() => { window.location.href = '/car/' + id }}>Dettagli</div>
            </div>
            <p className='text-black dark:text-white'>{model}</p>
            <p className='uppercase font-semibold text-black dark:text-white'>{transmission}</p>
            <div className='flex justify-center items-center'>
                {
                    loading && (
                        <Oval
                            height={50}
                            width={50}
                            color="#df7423"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={loading}
                            ariaLabel='oval-loading'
                            secondaryColor="#d9a075"
                            strokeWidth={3}
                            strokeWidthSecondary={3}
                        />
                    )
                }
                <div className='relative'>
                    <div className={'absolute top-0 right-0 w-5 h-5 rounded-full z-[50] shadow-sm'} style={{ backgroundColor: color }}></div>
                    <img src={image} className={`relative top-0 scale-125 transition-opacity ${loading ? 'hidden' : 'block'}`} alt={brand + " " + model} onLoad={() => setLoading(false)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} />
                    <div className={`absolute top-0 z-[8] w-full h-full bg-doot-white dark:bg-gray-800 transition-opacity ease-in-out duration-300 ${hover ? 'opacity-1' : 'opacity-0'}`}></div>
                    <img src={back_image} className={`absolute top-0 z-[10] scale-125 transition-opacity ease-in-out duration-300 ${loading ? 'hidden' : 'block'} ${hover ? 'opacity-1' : 'opacity-0'}`} alt={brand + " " + model} onLoad={() => setLoading(false)} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} />
                </div>
            </div>
            <div className='hidden justify-start items-center w-full'>
                <div className='border-solid border-b-2 border-gray-200 w-full'></div>
            </div>
            <div className='flex justify-between items-center mt-3'>
                <div className='mr-2'>
                    <p className='font-semibold text-lg text-black dark:text-white'>{km}</p>
                    <p className='text-black dark:text-white'>Km</p>
                </div>
                <div className='mr-2'>
                    <p className='font-semibold text-lg text-black dark:text-white'>{ghost ? "Si" : "No"}</p>
                    <p className='text-black dark:text-white'>Ghost</p>
                </div>
                <div>
                    <p className='font-bold text-lg text-black dark:text-white'>{current_use} <span className='text-sm'>settimane</span></p>
                    <p className='text-black dark:text-white'>Utilizzo attuale</p>
                </div>
            </div>
            {
                !ghost && (
                    <div className='flex justify-center items-center mt-4'>
                        <div className={`w-1/2 flex justify-center items-center border-2 border-gray-200 dark:border-gray-500 ${out ? "bg-[#df7423] text-white" : "text-black dark:text-white" }`}>
                            <p className=''>OUT</p>
                        </div>
                        <div className={`w-1/2 flex justify-center items-center border-2 border-gray-200 dark:border-gray-500 ${out ? "text-black dark:text-white" : "bg-[#df7423] text-white" }`}>
                            <p className=''>IN</p>
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Card