import React, { useState, useEffect } from 'react'
import Card from './Card'
import axios from '../../api'
import useUserState from '../../states/User'
import SingleCar from './SingleCar'
import { Oval } from 'react-loader-spinner'
import AddCarModal from './AddCarModal'


function Content({ carId, isDarkMode, setIsDarkMode }) {

	const [showAddCarModal, setShowAddCarModal] = useState(false)
	const [cars, setCars] = useState([])
	const [load, setLoad] = useState(false)

	const userState = useUserState()
	const user = userState.get()
	const current_dealer = userState.getCurrentDealer()

	useEffect(() => {
		setCars([])
		user.operator.dealers.map((dealer) => {
			axios.get('dealerVehicle?dealersId=' + dealer.id + '&limit=100000').then(res => {
				setCars(cars => [...cars, ...res.data.items])
				setLoad(true)
			})
		})
	}, [])

	useEffect(() => {
		// when addCarModal is true, we want to disable the scroll
		if (showAddCarModal) {
			document.body.style.overflow = 'hidden'
		} else {
			document.body.style.overflow = 'unset'
		}
	}, [showAddCarModal])

	return (
		<div>
			{
				showAddCarModal && (
					<div className='w-screen h-screen top-0 left-0 absolute backdrop-blur-md bg-white/30 dark:bg-gray-500/30 rounded-sm z-[20]'></div>
				)
			}
			{
				carId ? (
					<SingleCar id={carId} />
				) : (
					<>
						{!load ? (
							<div className='w-full flex justify-center'>
								<Oval
									height={20}
									width={20}
									color="#df7423"
									wrapperStyle={{}}
									wrapperClass=""
									ariaLabel='oval-loading'
									secondaryColor="#d9a075"
									strokeWidth={3}
									strokeWidthSecondary={3}
								/>
							</div>
						) : (
							<div>
								<div className='relative'>
									{
										showAddCarModal && (
											<AddCarModal setShowAddCarModal={setShowAddCarModal} />
										)
									}
									<div className='w-full flex justify-end'>
										<button className="transition duration-200 ease-in-out transform hover:-translate-y-1 hover:shadow-lg ml-5 px-6 py-2 font-medium tracking-wide text-white capitalize bg-[#df7423] rounded-lg" onClick={() => setShowAddCarModal(true)}>
											Aggiungi auto
										</button>
									</div>
									<div className="w-full flex justify-start py-4 px-6 flex-wrap">
										{cars?.map((car, index) => {
											if (car.dealer.id === user.operator.dealers[current_dealer].id)
												return (
													<Card key={index} index={index} id={car.id} brand={car.vehicle.model.manufacturer.name} model={car.vehicle.model.name} color={car.color_code} transmission={car.dealer.name} image={car.vehicle.front_image} back_image={car.vehicle.back_image} range={'412'} km={car.km_made ? car.km_made : "?"} ghost={car.ghost} current_use={'1'} out={car.status !== "available"} />
												)
										})}
									</div>
								</div>
							</div>
						)}
					</>
				)
			}
		</div >

	)
}

export default Content