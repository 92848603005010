import React from "react"
import Content from "../components/home/Content"

function Home() {

	return (
		<Content />
	)
}

export default Home