import React, { useState, useEffect } from 'react';
import Topbar from '../common/Topbar';
import Footer from '../common/Footer';
import ReactApexChart from "react-apexcharts";
import api from '../../api';
import useUserState from '../../states/User';

function Content() {

    const userState = useUserState()
    const user = userState.get()

    const [pieseries, setPieSeries] = useState([0, 0])
    const [pieoptions, setPieOptions] = useState({
        chart: {
            width: 380,
            type: 'pie',
        },
        colors: ['#b35e1d', '#df7423'],
        labels: ['Auto ferme', 'Auto occupate'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom',
                    horizontalAlign: 'center',
                }
            }
        }]
    })

    useEffect(() => {
        userState.get().operator.dealers.map((dealer) => {
			api.get('dealerVehicle?dealersId=' + dealer.id + '&limit=100000').then(res => {
				if (res.data.items.length > 0) {
                    res.data.items.map((car) => {
                        if (car.status === 'available') {
                            setPieSeries(pieseries => [pieseries[0] + 1, pieseries[1]])
                        } else {
                            setPieSeries(pieseries => [pieseries[0], pieseries[1] + 1])
                        }
                    })
                }
			})
		})
    }, [])

    return (
        <div className='w-full bg-gray-200 overflow-y-scroll'>
            <Topbar />
            <div className="p-6">
                <div className='w-full flex flex-col bg-white justify-around py-4 px-4 mb-6 rounded-md'>

                    <h1 className="mt-3 text-2xl font-semibold text-gray-800 md:text-3xl capitalize">
                        {new Date().toLocaleString('default', { month: 'long' })}
                    </h1>

                    <div className="flex justify-start items-center">
                        <div id="chart" className="w-1/2">
                            <ReactApexChart options={pieoptions} series={pieseries} type="pie" width={380} />
                        </div>
                        <div className='w-1/2'>

                        </div>
                    </div>

                </div>

                <Footer />
            </div>
        </div>
    )
}

export default Content